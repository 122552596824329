import {createAsyncThunk} from '@reduxjs/toolkit'
import getFlipAxiosInstance from '../../../utils/flipAxios'



const axiosInstance=getFlipAxiosInstance()
const login=createAsyncThunk('login',async({username,password})=>{
    // console.log('loginACtion',username,password);
    try {
        const res=await axiosInstance.post('/login',{username,password})
        // console.log('INFO','action res',res);
        return res.data
    } catch (error) {
        console.log('loginAction error',error);
    }
   
})

const logout=createAsyncThunk('logout',async(obj,{dispatch, getState, rejectWithValue, fulfillWithValue})=>{
    try {
        const res=await axiosInstance.post('/logout',{})
        console.log('INFO','logout success',res.data);
        // return {}
        return res.data
    } catch (error) {
        console.log('INFO','logout error',error.response);
        const errData={
                status:error.response.status,
                data:error.response.data,
            }

            // throw Error(JSON.stringify())
            return rejectWithValue(errData)
        
    }
})


const authAction={
    login,
    logout
}

export default authAction
