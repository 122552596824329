import React from "react";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";

/**
 * @typedef {Object} isActiveType
 * @property {string} rowColName
 */

/**
 * @typedef {Object} ActionColumn
 * @property {string} type - The type of the action (e.g., "view", "edit", "delete", "is-active").
 * @property {string} actionUrl - The URL template for the action.
 * @property {Object.<string, string>} actionUrlParamObj - An object mapping parameter keys to row property names.
 * @property {function(Object): void} [callback] - Optional callback function to be executed on button click.
 * @property {isActiveType} [isActive] - Optional callback function to be executed on button click.
 */

/**
 * Custom hook for generating action buttons in a data table.
 *
 * @param {Array} columns - The columns of the table.
 * @param {ActionColumn[]} actionColumns - The action column definitions.
 * @returns {Array} The updated columns with action buttons.
 */
const useFlipDataTableActionBtn = (columns, actionColumns) => {
  // @flip@ actionColumns format =[{type:string,actionUrl:'string', actionUrlParamObj:{paramkeyname:"rowkeyname"}}]

  if (columns && columns.length) {
    columns.push({
      name: "Action",
      cell: (row) => {
        return (
          <>
            {actionColumns.length
              ? actionColumns.map((actionCol, ind) => {
                  let toUrl = "";
                  if (actionCol.callback) {
                  } else {
                    toUrl = makeUrlParam(
                      actionCol?.actionUrl,
                      actionCol?.actionUrlParamObj,
                      row
                    );
                  }

                  // let toUrl = row.id;

                  const btnStyle = {
                    icon: "",
                    btnBgColor: "",
                  };
                  if (actionCol.type == "view") {
                    btnStyle.icon = "fa fa-eye";
                    btnStyle.btnBgColor = "primary";
                  } else if (actionCol.type == "edit") {
                    btnStyle.icon = "fa fa-pencil";
                    btnStyle.btnBgColor = "info";
                  } else if (actionCol.type == "delete") {
                    btnStyle.icon = "fa fa-trash";
                    btnStyle.btnBgColor = "danger";
                  } else if (actionCol.type == "is-active") {
                    btnStyle.icon = "fa fa-power-off";
                    btnStyle.btnBgColor = row[actionCol?.isActive?.rowColName]?'warning':'secondary';
                  }

                  return actionCol.callback ? (
                    <button
                      onClick={() => {
                        actionCol?.callback(row);
                      }}
                      key={ind}
                      className={`btn btn-${btnStyle.btnBgColor} btn-sm me-3`}
                    >
                      <i className={btnStyle.icon}></i>
                    </button>
                  ) : (
                    <Link
                      // to={`${actionCol.actionUrl}` + row.id}
                      to={toUrl}
                      key={ind}
                      className={`btn btn-${btnStyle.btnBgColor} btn-sm me-3`}
                    >
                      <i className={btnStyle.icon}></i>
                    </Link>
                  );
                })
              : ""}
          </>
        );
      },
    });
  }

  return columns;
};


/**
 * Constructs a URL with parameters from the row data.
 *
 * @param {string} url - The base URL template.
 * @param {Object.<string, string>} actionUrlParamObj - Object mapping parameter keys to row keys.
 * @param {Object} row - The current row data.
 * @returns {string} - The constructed URL.
 */
const makeUrlParam = (url, actionUrlParamObj, row) => {
  let urlStr = url;

  const urlparamsMatches = url ? [...url.matchAll(/:[\w]+/g)] : [];
  if (urlparamsMatches && urlparamsMatches.length) {
    urlparamsMatches.map((pm) => {
      let paramKey = pm[0];
      paramKey = paramKey.substring(1);
      let rowKey = actionUrlParamObj[paramKey];
      urlStr = urlStr.replace(pm, row[rowKey]);
    });
  }

  return urlStr;
  // return "https://google.com";
};

export default useFlipDataTableActionBtn;
