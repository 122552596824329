import { createSlice } from "@reduxjs/toolkit";
import locationAction from "./action";

const initialState = {
  states: [],
  districts: [],
  cities: [],
  pincodes: [],
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    locationResetAction: (state, action) => {
      state.states = [];
      state.districts = [];
      state.cities = [];
      state.pincodes = [];
    },
  },
  extraReducers: (builder) => {
    // for states
    builder.addCase(locationAction.getStates.fulfilled, (state, action) => {
      state.states = action.payload.states;
    });
    // for districts
    builder.addCase(locationAction.getDistricts.fulfilled, (state, action) => {
      state.districts = action.payload.districts;
    });
    // for citites
    builder.addCase(locationAction.getCities.fulfilled, (state, action) => {
      state.cities = action.payload.cities;
    });
  },
});
export const { locationResetAction } = locationSlice.actions;
export const locationReducer = locationSlice.reducer;
