import React from "react";
import DataTable from "react-data-table-component";
const FlipDataTable = ({ columns = [], data = [] }) => {
  // if (columns && columns.length) {
  //   columns.push({
  //     name: "Action",
  //     cell: (row) => (
  //       <>
  //         <button>view</button>
  //       </>
  //     ),
  //   });
  // }

  //   const columns = [
  //     { name: "Name", selector: (row) => row.name, sortable: true },
  //     { name: "Email", selector: (row) => row.email },
  //     { name: "Age", selector: (row) => row.age },
  //   ];

  //   const data = [
  //     {
  //       id: 1,
  //       name: "Liam Miller",
  //       email: "liam.miller@example.com",
  //       age: 25,
  //     },
  //     {
  //       id: 2,
  //       name: "Olivia Jones",
  //       email: "olivia.jones@example.com",
  //       age: 30,
  //     },
  //     {
  //       id: 3,
  //       name: "Noah Brown",
  //       email: "noah.brown@example.com",
  //       age: 22,
  //     },
  //     {
  //       id: 4,
  //       name: "Emma Garcia",
  //       email: "emma.garcia@example.com",
  //       age: 28,
  //     },
  //     {
  //       id: 5,
  //       name: "William Wilson",
  //       email: "william.wilson@example.com",
  //       age: 35,
  //     },
  //     {
  //       id: 6,
  //       name: "Ava Rodriguez",
  //       email: "ava.rodriguez@example.com",
  //       age: 21,
  //     },
  //     {
  //       id: 7,
  //       name: "James Smith",
  //       email: "james.smith@example.com",
  //       age: 40,
  //     },
  //     {
  //       id: 8,
  //       name: "Isabella Johnson",
  //       email: "isabella.johnson@example.com",
  //       age: 27,
  //     },
  //     {
  //       id: 9,
  //       name: "Benjamin Davis",
  //       email: "benjamin.davis@example.com",
  //       age: 32,
  //     },
  //     {
  //       id: 10,
  //       name: "Sophia Miller",
  //       email: "sophia.miller@example.com",
  //       age: 19,
  //     },
  //     {
  //       id: 11,
  //       name: "Mason Garcia",
  //       email: "mason.garcia@example.com",
  //       age: 24,
  //     },
  //     {
  //       id: 12,
  //       name: "Evelyn Thomas",
  //       email: "evelyn.thomas@example.com",
  //       age: 38,
  //     },
  //     {
  //       id: 13,
  //       name: "Lucas Williams",
  //       email: "lucas.williams@example.com",
  //       age: 20,
  //     },
  //     {
  //       id: 14,
  //       name: "Charlotte Brown",
  //       email: "charlotte.brown@example.com",
  //       age: 26,
  //     },
  //     {
  //       id: 15,
  //       name: "Aiden Jackson",
  //       email: "aiden.jackson@example.com",
  //       age: 31,
  //     },
  //     {
  //       id: 16,
  //       name: "Mia Rodriguez",
  //       email: "mia.rodriguez@example.com",
  //       age: 23,
  //     },
  //     {
  //       id: 17,
  //       name: "Ethan Miller",
  //       email: "ethan.miller@example.com",
  //       age: 18,
  //     },
  //     {
  //       id: 18,
  //       name: "Harper Garcia",
  //       email: "harper.garcia@example.com",
  //       age: 34,
  //     },
  //     {
  //       id: 19,
  //       name: "Michael Thomas",
  //       email: "michael.thomas@example.com",
  //       age: 39,
  //     },
  //     {
  //       id: 20,
  //       name: "Abigail Williams",
  //       email: "abigail.williams@example.com",
  //       age: 29,
  //     },
  //   ];

  const [tableData, setTableData] = React.useState(data);

  const handleFilter = (searchInput) => {
    // const newData = data.filter((item) => {});
    const newData = data.filter((item) => {
      let flg = false;
      Object.keys(item).map((key) => {
        // console.log("item[key]", item[key]);
        const tmp = `${item[key]}`.includes(searchInput);
        if (tmp) {
          flg = true;
        }
      });
      console.log({ flg });
      return flg;
    });
    setTableData(newData);
  };

  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <>
      <div className="row justify-content-end">
        <div className="col-4 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="search here..."
            onChange={(e) => handleFilter(e.target.value)}
          />
        </div>
      </div>
      <DataTable columns={columns} data={tableData} fixedHeader pagination />
    </>
  );
};

export default FlipDataTable;
