import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { decrement, increment } from "../redux/slice/counter/counterSlice";
import authAction from "../redux/slice/auth/action";

const TestPage = () => {
  const state = useSelector((state) => state);
  const count=state.count?.value??0
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(authAction.login({username:'superadmin',password:'abc123'}));


  },[dispatch]);

  return (
    <div>
      <div>
        <div>
          <button
            aria-label="Increment value"
            onClick={() => dispatch(increment())}
          >
            Increment
          </button>
          <span>{count}</span>
          <button
            aria-label="Decrement value"
            onClick={() => dispatch(decrement())}
          >
            Decrement
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestPage;
