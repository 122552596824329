import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup'
import branchManagerAction from '../../redux/slice/branch-manager/action';
import BaseLayout from '../panel/layouts/BaseLayout';
import FlipInput from '../../components/shared/forms/inputs/FlipInput';


const schema = yup
  .object({
    username: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().required(),
    mobile: yup.string().required(),
    password: yup.string().nullable()

  })
  .required();

const BranchManagerEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  // get redux-state data
  const stateData = useSelector((state) => state.branchManager);

  // comp-state
  const [formValues, setFormValues] = React.useState({})

  React.useEffect(() => {
    dispatch(branchManagerAction.view(params.id))
  }, []);

  // form submission section
  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: formValues
  });


  // effect depend
  React.useEffect(() => {
    setFormValues({
      username: stateData?.view?.username,
      name: stateData?.view?.name,
      email: stateData?.view?.email,
      mobile: stateData?.view?.mobile,
      password: '',
    })
  }, [stateData.view])

  const onSubmit = (data) => {
    console.log('branchmanageraddpage onsubmit data ', { id: params.id, data })

    dispatch(branchManagerAction.edit({ id: params.id, data }))
      .unwrap()
      .then((result) => {
        console.log({ result });
        navigate("/branch-manager");
      })
      .catch((err) => { });
  }

  return (
    <>
      <BaseLayout pageTitle={'BranchManager Edit'}>

        <div className="row">
          <div className="offset-lg-3 col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className='row g-3'>

                  <div className="col-12">
                    <FlipInput
                      labelTitle='Username'
                      register={register('username')}
                      errors={errors}
                    />
                  </div>
                  <div className="col-12">
                    <FlipInput
                      labelTitle='Name'
                      register={register('name')}
                      errors={errors}
                    />
                  </div>
                  <div className="col-12">
                    <FlipInput
                      labelTitle='Email'
                      register={register('email')}
                      errors={errors}
                    />
                  </div>
                  <div className="col-12">
                    <FlipInput
                      labelTitle='Mobile'
                      register={register('mobile')}
                      errors={errors}
                    />
                  </div>
                  <div className="col-12">
                    <FlipInput
                      labelRequired='optional'
                      inputType={'text'}
                      labelTitle='Password (only for password change)'
                      register={register('password')}
                      errors={errors}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="" className='form-label'></label>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </BaseLayout>
    </>
  )
}

export default BranchManagerEditPage