import { createAsyncThunk } from "@reduxjs/toolkit";
import getFlipAxiosInstance, {
  axiosErrorThrow,
} from "../../../utils/flipAxios";
import flipLog from "../../../utils/logs/log.util";
import { BACKEND_API } from "../../../_config/constants/backend_api";

const axiosInstance = getFlipAxiosInstance();

const list = createAsyncThunk("list", async () => {
  try {
    const res = await axiosInstance.get(BACKEND_API.URL + "/branch-manager");
    return res.data;
  } catch (error) {
    flipLog.InfoDanger("branchManager action ", error);
    axiosErrorThrow(error);
  }
});
const view = createAsyncThunk("view", async (id) => {
  try {
    const res = await axiosInstance.get(BACKEND_API.URL + "/branch-manager/"+id);
    return res.data;
  } catch (error) {
    flipLog.InfoDanger("branchManager action view", error);
    axiosErrorThrow(error);
  }
});

const add = createAsyncThunk("add", async (data) => {
  try {
    const res = await axiosInstance.post(BACKEND_API.URL + "/branch-manager",data);
    console.log('branch-manager action',{res:res});
    return res.data;
  } catch (error) {
    flipLog.InfoDanger("branchManager action add", error);
    axiosErrorThrow(error);
  }
});
const edit = createAsyncThunk("edit", async ({id,data}) => {
  try {
    const res = await axiosInstance.put(BACKEND_API.URL + "/branch-manager/"+id,data);
    return res.data;
  } catch (error) {

    flipLog.InfoDanger("branchManager action.js edit", error);
    axiosErrorThrow(error);
  }
});

// const getBranch = createAsyncThunk("getBranch", async (id, { getState }) => {
//   // console.log("INFO getBranch", id);
//   try {
//     const res = await axiosInstance.get(BACKEND_API.URL + "/branch/" + id);
//     return res.data;
//   } catch (error) {
//     flipLog.InfoDanger("branch action getBranch", error);
//     axiosErrorThrow(error);
//   }
// });

// const addBranch = createAsyncThunk("branchAddAction", async (reqBody) => {
//   try {
//     const res = await axiosInstance.post(BACKEND_API.URL + "/branch", reqBody);
//     console.log({ branchAddAction: res.data });
//     return res.data;
//   } catch (error) {
//     axiosErrorThrow(error);
//   }
// });
// const deleteBranch = createAsyncThunk("deleteBranch", async (id) => {
//   try {
//     const res = await axiosInstance.delete(BACKEND_API.URL + "/branch/" + id);
//     console.log({ branchAddAction: res.data });
//     return res.data;
//   } catch (error) {
//     flipLog.InfoDanger("deleteBranch", error);
//     axiosErrorThrow(error);
//   }
// });

const branchManagerAction = {
  list,
  view,
  add,
  edit
};

export default branchManagerAction;
