import flipAlert from "../utils/alert/flipAlert";
import getFlipAxiosInstance, { axiosResponseError } from "../utils/flipAxios";
import flipLog from "../utils/logs/log.util";

const axiosInstance = getFlipAxiosInstance();

const prefixUrl = `/parcel`;
const messagePrfix = `Parcel`;
const list = async () => {
  try {
    const res = await axiosInstance.get(`${prefixUrl}`);

    return res.data;
  } catch (error) {
    console.log(`${messagePrfix} list error`, error);
    flipAlert.showError(axiosResponseError(error));
  }
};
const add = async (data) => {
  try {
    const res = await axiosInstance.post(`${prefixUrl}`, data);

    return res.data;
  } catch (error) {
    console.log(`${messagePrfix} add error`, error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const edit = async ({ id, data }) => {
  try {
    const res = await axiosInstance.put(`${prefixUrl}/${id}`, data);

    return res.data;
  } catch (error) {
    console.log(`${messagePrfix} add error`, error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const view = async (id) => {
  try {
    const res = await axiosInstance.get(`${prefixUrl}/${id}`);

    return res.data;
  } catch (error) {
    console.log(`${messagePrfix} add error`, error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const parcelService = {
  add,
  list,
  edit,
  view,
};
export default parcelService;
