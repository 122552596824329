import React from "react";
import BaseLayout from "../panel/layouts/BaseLayout";
import FlipSelect from "../../components/shared/forms/inputs/FlipSelect";
import { useDispatch, useSelector } from "react-redux";
import locationAction from "../../redux/slice/location/action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import FlipInput from "../../components/shared/forms/inputs/FlipInput";
import FlipInputTextArea from "../../components/shared/forms/inputs/FlipInputTextArea";
import ReactSelect from "react-select";
import BranchMapper from "../../_http/mapper/branch.mapper";
import branchAction from "../../redux/slice/branch/action";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.string().required(),
    // country: yup.string().required(),
    state: yup.object().required(),
    district: yup.object().required(),
    city: yup.object().required(),
    pin: yup.string().required(),
  })
  .required();

const BranchAddPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationState = useSelector((state) => state.location);
  const [stateOptions, setStateOptions] = React.useState([]);
  const [distrctOptions, setDistrctOptions] = React.useState([]);
  const [citiesOptions, setCitiesOptions] = React.useState([]);
  const [userInput, setUserInput] = React.useState({
    state: "",
    district: "",
    city: "",
  });

  // form
  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    dispatch(locationAction.getStates());
  }, []);

  React.useEffect(() => {
    // setStateOptions({value:'',label:'--SELECT--'})
    const stateOpts = locationState.states.map((state) => {
      return {
        value: state.id,
        label: state?.name,
      };
    });
    // stateOpts.push(0,{value:'',label:'--SELECT--'})
    setStateOptions(stateOpts);

    if (userInput.state) {
      setDistrctOptions(
        locationState.districts?.map((item) => {
          return {
            value: item.id,
            label: item?.name,
          };
        })
      );
    }
    if (userInput.district) {
      setCitiesOptions(
        locationState.cities?.map((item) => {
          return {
            value: item.id,
            label: `${item.office_name} [PIN:${item.pincode}]`,
          };
        })
      );
    }
  }, [locationState]);

  React.useEffect(() => {
    if (userInput.location_state_id) {
      dispatch(locationAction.getDistricts(userInput.location_state_id));
    }
  }, [userInput]);

  // ===============handler================

  const handleState = async (data) => {
    console.log("INFO", "handleState", data);
    // const value = data.value
    setUserInput({ state: data, district: "", city: "" });
    setValue("pin", "");
    if (data) {
      dispatch(locationAction.getDistricts(data.value));
    }
  };

  const handleDistrict = async (data) => {
    const id = data.value;
    setUserInput({ ...userInput, district: data, city: "" });
    dispatch(locationAction.getCities({ district_id: id }));
    setValue("pin", "");
  };

  const handleCity = async (data) => {
    const id = data.value;
    setUserInput({ city: data });
    const city = locationState.cities.find((item) => item.id == data.value);
    console.log({ cityData: data });
    if (city) {
      console.log({ city });
      setValue("pin", city.pincode);
      clearErrors("pin");
    }

    // setUserInput({ ...userInput, district:data })
    // dispatch(locationAction.getCities({ district_id: id }))
  };

  // handle form submit
  const onSubmit = (data) => {
    console.log(data);

    const reqBody = {
      ...data,
      state: data?.state?.value,
      district: data?.district?.value,
      city: data?.city?.value,
    };

    const reqBodyMapData = BranchMapper.createRequest(reqBody);

    dispatch(branchAction.addBranch(reqBodyMapData))
      .unwrap()
      .then((result) => {
        console.log({ result });
        navigate("/branch");
      })
      .catch((err) => {});
  };

  return (
    <BaseLayout pageTitle={"Branch Add"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                <div className="col-12">
                  {/* <label htmlFor="" className='form-label'>Name</label>
                                    <input {...register('name')} type="text" className="form-control" />
                                    <p className="text-danger">{errors.name?.message}</p> */}
                  <FlipInput
                    inputType="text"
                    labelTitle={"Name"}
                    labelRequired
                    register={register("name")}
                    errors={errors}
                  />
                </div>
                <div className="col-12">
                  {/* <label htmlFor="" className='form-label'>Address</label> */}
                  {/* <textarea name="" id="" className="form-control"></textarea> */}

                  <FlipInputTextArea
                    labelTitle={"Address"}
                    labelRequired
                    register={register("address")}
                    errors={errors}
                  />
                </div>

                {/* country input */}
                <div className="col-12">
                  <label htmlFor="" className="form-label">
                    Country
                  </label>
                  <select name="" id="" className="form-control">
                    <option value="India">India</option>
                  </select>
                </div>

                {/* State select input */}
                <div className="col-12">
                  <FlipSelect
                    labelRequired
                    labelTitle={"State"}
                    register={register("state")}
                    control={control}
                    options={stateOptions}
                    errors={errors}
                    onChange={(data) => handleState(data)}
                    // value={userInput.state}
                  />
                </div>

                <div className="col-12">
                  <FlipSelect
                    labelRequired
                    labelTitle={"District"}
                    register={register("district")}
                    errors={errors}
                    control={control}
                    options={distrctOptions}
                    onChange={(data) => {
                      handleDistrict(data);
                    }}
                    value={userInput.district}
                  />
                </div>

                <div className="col-12">
                  <FlipSelect
                    placeholder="type your city"
                    isSearchable={true}
                    labelTitle={"City/ PostOffice"}
                    labelRequired
                    register={register("city")}
                    errors={errors}
                    control={control}
                    options={citiesOptions}
                    onChange={handleCity}
                    value={userInput.city}
                  />
                </div>

                <div className="col-12">
                  <FlipInput
                    inputVisibility={"readonly"}
                    labelTitle={"PIN"}
                    labelRequired
                    register={register("pin")}
                    errors={errors}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default BranchAddPage;
