function _getCallerFile() {
  var originalFunc = Error.prepareStackTrace;

  var callerfile;
  try {
    var err = new Error();
    var currentfile;

    Error.prepareStackTrace = function (err, stack) {
      return stack;
    };

    currentfile = err.stack.shift().getFileName();

    while (err.stack.length) {
      callerfile = err.stack.shift().getFileName();

      if (currentfile !== callerfile) break;
    }
  } catch (e) {}

  Error.prepareStackTrace = originalFunc;

  return callerfile;
}

function _getCallerFile2(){
  // const error = new Error();
  //   console.log("Function called from:", error.stack.split("\n")[1].trim());
  //   console.log("Function defined in:", __filename);


  const error = new Error();
  const stack = error.stack.split("\n");

  // The line at index 2 usually contains the caller information
  const callerLine = stack[2];
  const match = callerLine.match(/\((.*):(\d+):(\d+)\)/);

  // if (match) {
  //   const [_, filePath, lineNumber, columnNumber] = match;
  //   console.log(`Called from: ${filePath}:${lineNumber}:${columnNumber}`);
  // } else {
  //   console.log("Caller location could not be determined");
  // }
}

// utils.js
export const logCallLocation = () => {
  const error = new Error();
  const stack = error.stack.split("\n");

  // The line at index 2 usually contains the caller information
  const callerLine = stack[2];
  const match = callerLine.match(/\((.*):(\d+):(\d+)\)/);

  if (match) {
    const [_, filePath, lineNumber, columnNumber] = match;
    console.log(`Called from: ${filePath}:${lineNumber}:${columnNumber}`);
  } else {
    console.log("Caller location could not be determined");
  }
};


// =========================

const Info = (message = "", data = "") => {
  if (typeof message == "object") {
    message = JSON.stringify(message);
  }
  if (typeof data == "object") {
    data = JSON.stringify(data);
  }

  let fileName = _getCallerFile()?.split("/");
  fileName = fileName[fileName.length - 1];

  // console.log(
  //   "\x1b[36m",
  //   "INFO",
  //   "\x1b[0m",
  //   `${fileName}::${message}:: ${data}`
  // );
  let style = "color: #43c52f; font-size:14px;";
  let commonStyle = "font-size:14px;";
  console.log(
    `%c${fileName} :: %c${message} ${data ? " :: " + data : ""}`,
    style,
    commonStyle
  );

  console.log();
};
const InfoDanger = (message = "", data = "") => {
  // if (typeof message == "object" || message != "") {
  //   message = JSON.stringify(message);
  // }
  // if (typeof data == "object" || data != "") {
  //   data = JSON.stringify(data);
  // }

  // let fileName = _getCallerFile()?.split("/");
  // fileName = fileName[fileName.length - 1]??'';

  // console.log(
  //   "\x1b[31m",
  //   "ERROR",
  //   "\x1b[0m",
  //   ` ${fileName}::${message}:: ${data}`
  // );
  let style = "color: #ff3366; font-size:14px;";
  // console.log(`%c${fileName}::${message}:: ${data}`, style);
  // console.log(`${fileName}::${message}::`);
  console.log(`${message}::`);
  console.log(`${data}`);

  console.log();
};
const InfoWarning = (message = "", data = "") => {
  if (typeof message == "object" || message != "") {
    message = JSON.stringify(message);
  }
  if (typeof data == "object" || data != "") {
    data = JSON.stringify(data);
  }

  let fileName = _getCallerFile().split("/");
  fileName = fileName[fileName.length - 1];

  // console.log(
  //   "\x1b[33m",
  //   "WARNING",
  //   "\x1b[0m",
  //   ` ${fileName}::${message}:: ${data}`
  // );
  let style = "color: #ffcc55; font-size:14px;";
  console.log(`%c${fileName}::${message}:: ${data}`, style);

  console.log();
};

// ------------------
const flipLog = {
  Info,
  InfoDanger,
  InfoWarning,
};

export default flipLog;
