import React from "react";
import { getNestedValue } from "../../../../utils/data/js-object.util";

const FlipInputTextArea = ({
  labelTitle,
  labelRequired,
  errors,
  register,
  inputRows = 3,
  ...props
}) => {
  if (!register) {
    register = {};
  }

  // console.log({ register });
  return (
    <>
      <label htmlFor="" className="form-label">
        {labelRequired && (
          <span className="text-danger font-weight-bold"> *</span>
        )}
        {labelTitle} {!labelRequired ? "(optional)" : ""}
      </label>
      <textarea
        {...register}
        className="form-control"
        rows={inputRows}
      ></textarea>
      <p className="text-danger">
        {errors && getNestedValue(errors, register.name)?.message}
      </p>
    </>
  );
};

export default FlipInputTextArea;
