import React from "react";
import getFlipAxiosInstance from "../../../utils/flipAxios";
import flipLog from "../../../utils/logs/log.util";

const axiosInstance = getFlipAxiosInstance({ isBaseURL: false });

const ImageViewComp = ({ url }) => {
  const [image, setImage] = React.useState("");

  React.useEffect(() => {
    console.log("ImageViewComp", { url, image });
    axiosInstance
      .get(url)
      .then((res) => {
        setImage(res.data);
      })
      .catch((err) => {
        // flipLog.InfoDanger("image url error:", err);
        console.log("image url error:", err);
      });
  }, [url]);

  return image && <img className="w-100" src={url} alt="" />;
};

export default ImageViewComp;
