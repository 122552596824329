import React from 'react'
import Images from '../../../_config/constants/image'
import TextContent from '../../../_config/constants/string'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'
import {useDispatch,useSelector} from 'react-redux'
import authAction from '../../../redux/slice/auth/action'

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required()


const LoginPage = () => {
  const dispatch=useDispatch()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({resolver:yupResolver(schema)})

  const onSubmit = (data) => {
    // console.log(errors);
    // console.log(data)
    dispatch(authAction.login(data))


  }


  return (
    <div className="container">
  <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex justify-content-center py-4">
            <a href="index.html" className="logo d-flex align-items-center w-auto">
              <img src={Images.Logo} alt='logo' />
              <span className="d-none d-lg-block">{TextContent.appname}</span>
            </a>
          </div>{/* End Logo */}
          <div className="card mb-3">
            <div className="card-body">
              <div className="pt-4 pb-2">
                <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                <p className="text-center small">Enter your username &amp; password to login</p>
              </div>
              <form className="row g-3 needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                  <label htmlFor="yourUsername" className="form-label">Username</label>
                  <div className="input-group has-validation">
                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                    <input {...register('username')} type="text" name="username" className="form-control" id="yourUsername"  />
                </div>
                    <div className="text-danger">{errors.username?.message}</div>
                  </div>
                <div className="col-12">
                  <label htmlFor="yourPassword" className="form-label">Password</label>
                  <input {...register('password')} type="password" name="password" className="form-control" id="yourPassword"  />
                  <div className="text-danger">{errors.password?.message}</div>
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="remember" defaultValue="true" id="rememberMe" />
                    <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100" type="submit">Login</button>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default LoginPage