import * as alertify from "alertifyjs"; // Import your preloader library here
import "alertifyjs/build/css/alertify.css"; // Example: Import CSS if needed
import "./flip-preloader.css";


let el=document.createElement('div')
el.classList='flip-preloader-div'
el.innerHTML=`
    <div class="flip-preloader spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
    `

document.body.appendChild(el)


const showPreloader = () => {
//   alertify.set("notifier", "position", "top-center"); // Customize preloader settings
//   alertify.notify("Loading...", "custom", 0);
    el.style.display='block';
};

const hidePreloader = () => {
    el.style.display='none';
//   alertify.dismissAll(); // Dismiss or hide the preloader
};

const flipPreloader = {
  showPreloader,
  hidePreloader,
};

export default flipPreloader;
