import React from 'react'
import BaseLayout from '../panel/layouts/BaseLayout'
import { Link } from 'react-router-dom'
import RouteUrl from '../../_config/constants/route_url'
import { useDispatch, useSelector } from 'react-redux'
import useFlipDataTableActionBtn from '../../hooks/useFlipDataTableActionBtn'
import assignedManagerAction from '../../redux/slice/assigned-manager/action'
import branchAction from '../../redux/slice/branch/action'
import branchManagerAction from '../../redux/slice/branch-manager/action'
import FlipDataTable from '../../components/flip-table/FlipDataTable'

const AssignedManagerIndexPage = () => {

  const assignedManagerState = useSelector(state => state.assignedManager)

  const dispatch = useDispatch()

  React.useEffect(() => {

    dispatch(assignedManagerAction.list())
    // dispatch(branchAction.getAllBranch());
    // dispatch(branchManagerAction.list())
  }, [])




  const columns = useFlipDataTableActionBtn([
    {
      name: 'Branch Name',
      selector: row => row?.branch?.name
    },
    {
      name: 'Manager Name',
      selector: row => row?.manager?.name
    },
  ],
    [
      {
        type: 'edit',
        actionUrl: "/assigned-manager/:id/edit",
        actionUrlParamObj: { id: "id" },
      },
      {
        type: 'delete',
      }
    ]
  )
  return (
    <BaseLayout pageTitle={"Assgined Manager"}>
      <div className="row mb-3">
        <div className="col-12">
          <Link className="btn btn-primary" to={RouteUrl.assignedManager.create}>Add</Link>
        </div>
      </div>
      <FlipDataTable
        columns={columns}
        data={assignedManagerState.list}
      />


    </BaseLayout>
  )
}

export default AssignedManagerIndexPage