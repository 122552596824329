import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./slice/auth/authSlice";
import counterReducer from "./slice/counter/counterSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore, REGISTER } from "redux-persist";
import { locationReducer } from "./slice/location/locationSlice";
import { branchReducer } from "./slice/branch/branchSlice";
import { branchManagerReducer } from "./slice/branch-manager/branchManagerSlice";
import { assignedManagerReducer } from "./slice/assigned-manager/assignedManagerSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  // counter:counterReducer,
  auth: authReducer,
  // auth2:authReducer,
  location: locationReducer,
  branch: branchReducer,
  branchManager: branchManagerReducer,
  assignedManager: assignedManagerReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: { ignoreActions: [] },
      // serializableCheck: { ignoreActions:[REGISTER] }
    });
  },
});

export const persistor = persistStore(store);
