import flipAlert from "../../utils/alert/flipAlert";
import getFlipAxiosInstance, { axiosResponseError } from "../../utils/flipAxios";


const axiosInstance = getFlipAxiosInstance()

const list = async () => {
    try {
        const res = await axiosInstance.get('/parcel-types')

        return res.data
    } catch (error) {
        console.log('parcel list error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const store = async (data) => {
    try {
        const res = await axiosInstance.post('/parcel-types', data)

        return res.data
    } catch (error) {
        console.log('parcel add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const update = async ({ id, data }) => {
    try {
        const res = await axiosInstance.put(`/parcel-types/${id}`, data)

        return res.data
    } catch (error) {
        console.log('parcel update error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const show = async (id) => {
    try {
        const res = await axiosInstance.get(`/parcel-types/${id}`)

        return res.data
    } catch (error) {
        console.log('parcel add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}
const isActive = async (id) => {
    try {
        const res = await axiosInstance.get(`/parcel-types/is-active/${id}`)

        return res.data
    } catch (error) {
        console.log('parcel add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const parcelTypeService = {
    list,
    store,
    update,
    show,
    isActive
}

export default parcelTypeService