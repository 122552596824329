import React from "react";
import ImageViewComp from "./ImageViewComp";

const DataViewComp = ({ dataInfo }) => {
  return (
    <>
      {dataInfo && dataInfo.length
        ? dataInfo.map((row, ind) => {
            if (row?.type == "image") {
              return (
                <div key={ind} className="row py-3 border">
                  <div className="col">{row.label}</div>
                  <div className="col">
                    <a target="_blank" href={row.value}>
                      {/* <img style={{ width: "150px" }} src={row.value} alt="" /> */}
                      <ImageViewComp url={row.value} />
                    </a>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={ind} className="row py-3 border">
                  <div className="col">{row.label}</div>
                  <div className="col">{row.value}</div>
                </div>
              );
            }
          })
        : ""}
    </>
  );
};

export default DataViewComp;
