import React from "react";

const _convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const FlipInputFile = ({
  labelTitle,
  labelRequired,
  errors,
  register,
  setValue,
  clearErrors,
  ...props
}) => {
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const res = await _convertBase64(file);
    console.log({ res });
    // fileInpTextRef.current = res;
    setValue(register.name, res);
    clearErrors(register.name);
  };

  return (
    <>
      <label htmlFor="" className="form-label">
        {labelRequired && (
          <span className="text-danger font-weight-bold"> *</span>
        )}
        {labelTitle} {!labelRequired ? "(optional)" : ""}
      </label>
      <input {...register} type="hidden" />
      <input type="file" className="form-control" onChange={handleFileChange} />
      <p className="text-danger">{errors && errors[register.name]?.message}</p>
    </>
  );
};

export default FlipInputFile;
