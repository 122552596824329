import React from "react";
import BaseLayout from "../../panel/layouts/BaseLayout";
import RouteUrl from "../../../_config/constants/route_url";
import { Link } from "react-router-dom";
import parcelTypeService from "../../../services/master/parcel-type.service";
import FlipDataTable from "../../../components/flip-table/FlipDataTable";
import useFlipDataTableActionBtn from "../../../hooks/useFlipDataTableActionBtn";
import flipAlert from "../../../utils/alert/flipAlert";

const ParcelDeliveryStatusTypeIndexPage = () => {
  const [parcelTypes, setparcelTypes] = React.useState([]);

  const columns = useFlipDataTableActionBtn(
    [
      {
        name: "Name",
        selector: (row) => row.name,
      },
    ],
    [
      {
        type: "view",
        actionUrl: "/parcel-types/:id",
        actionUrlParamObj: { id: "id" },
      },
      {
        type: "edit",
        actionUrl: "/parcel-types/:id/edit",
        actionUrlParamObj: { id: "id" },
      },
      {
        type: "delete",
        callback: (data) => {
          if (window.confirm("are u sure ?")) {
            console.log(data);
          }
        },
      },
      {
        type: "is-active",
        isActive: { rowColName: "is_active" },
        callback: (data) => {
          parcelTypeService.isActive(data.id).then((res) => {
            // show notification
            flipAlert.showSuccess(res?.message);

            // find index and change is_active status
            const tempPt = [...parcelTypes];
            const rowIndex = parcelTypes.findIndex(
              (item) => item.id == data.id
            );
            tempPt[rowIndex] = { ...data, is_active: res?.status };
            setparcelTypes(tempPt);
          });
        },
      },
    ]
  );

  React.useEffect(() => {
    parcelTypeService.list().then((res) => {
      console.log({ res });
      setparcelTypes(res.parcelTypes);
    });
  }, []);

  return (
    <BaseLayout pageTitle={"Parcel Delivery Status"}>
      <div className="row mb-3">
        <div className="col-12">
          <Link className="btn btn-primary" to={RouteUrl.parcelType.create}>
            Add
          </Link>
        </div>
      </div>
      <FlipDataTable columns={columns} data={parcelTypes} />
    </BaseLayout>
  );
};

export default ParcelDeliveryStatusTypeIndexPage;
