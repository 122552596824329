import { createAsyncThunk } from "@reduxjs/toolkit";
import getFlipAxiosInstance, {
  axiosErrorThrow,
} from "../../../utils/flipAxios";
import flipLog from "../../../utils/logs/log.util";
import { BACKEND_API } from "../../../_config/constants/backend_api";

const axiosInstance = getFlipAxiosInstance();

const getAllBranch = createAsyncThunk("getAllBranch", async () => {
  try {
    const res = await axiosInstance.get(BACKEND_API.URL + "/branch");
    return res.data;
  } catch (error) {
    flipLog.InfoDanger("branch action getAllBranch", error);
    axiosErrorThrow(error);
  }
});
const getBranch = createAsyncThunk("getBranch", async (id, { getState }) => {
  // console.log("INFO getBranch", id);
  try {
    const res = await axiosInstance.get(BACKEND_API.URL + "/branch/" + id);
    return res.data;
  } catch (error) {
    console.log('from action error',error);
    flipLog.InfoDanger("branch action getBranch", error);
    axiosErrorThrow(error);
  }
});

const addBranch = createAsyncThunk("branchAddAction", async (reqBody) => {
  try {
    const res = await axiosInstance.post(BACKEND_API.URL + "/branch", reqBody);
    console.log({ branchAddAction: res.data });
    return res.data;
  } catch (error) {
    axiosErrorThrow(error);
    // throw error
  }
});
const deleteBranch = createAsyncThunk("deleteBranch", async (id) => {
  try {
    const res = await axiosInstance.delete(BACKEND_API.URL + "/branch/" + id);
    console.log({ branchAddAction: res.data });
    return res.data;
  } catch (error) {
    flipLog.InfoDanger("deleteBranch", error);
    axiosErrorThrow(error);
  }
});

const branchAction = {
  addBranch,
  getAllBranch,
  getBranch,
  deleteBranch,
};

export default branchAction;
