import React from 'react'
import BaseLayout from '../panel/layouts/BaseLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { yup } from '../../utils/pkgs';
import { FlipInput, FlipInputTextArea, FlipSelect } from '../../components/shared/forms/inputs';


const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.string().required(),
    // country: yup.string().required(),
    state: yup.object().required(),
    district: yup.object().required(),
    city: yup.object().required(),
    pin: yup.string().required(),
  })
  .required();

const BranchEditPage = () => {


  // form submit section
  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // handle form submit
  const onSubmit = (data) => {
    console.log(data);

    const reqBody = {
      ...data,
      state: data.state.value,
      district: data.district.value,
      city: data.city.value,
    };
  }


  return (
    <BaseLayout pageTitle={"Branch Edit"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                <div className="col-12">
                  <FlipInput
                    inputType="text"
                    labelTitle={"Name"}
                    labelRequired
                    register={register("name")}
                    errors={errors}
                  />
                </div>


                <div className="col-12">
                  <FlipInput
                    inputVisibility={"readonly"}
                    labelTitle={"PIN"}
                    labelRequired
                    register={register("pin")}
                    errors={errors}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default BranchEditPage