const RouteUrl = {
  dashboard: "/dashboard",
  branch: (() => {
    const prefix = "/branch";
    return {
      index: prefix,
      create: `${prefix}/create`,
    };
  })(),
  branchManager: (() => {
    const prefix = "/branch-manager";
    return {
      index: prefix,
      create: `${prefix}/create`,
    };
  })(),
  assignedManager: (() => {
    const prefix = "/assigned-manager";
    return {
      index: prefix,
      create: `${prefix}/create`,
    };
  })(),
  party: (() => {
    const prefix = "/party";
    return {
      index: `${prefix}/`,
      create: `${prefix}/create`,
    };
  })(),
  parcelType: (() => {
    const prefix = "/parcel-types";
    return {
      index: `${prefix}/`,
      create: `${prefix}/create`,
    };
  })(),
  parcelDeliveryStatusType: (() => {
    const prefix = "/parcel-delivery-status-types";
    return {
      index: `${prefix}/`,
      create: `${prefix}/create`,
    };
  })(),
  parcel: (() => {
    const prefix = "/parcel";
    return {
      index: `${prefix}/`,
      create: `${prefix}/create`,
    };
  })(),

  //   -------- manager section-------------

  managerSection: (() => {
    return {
      parcelRate: {
        edit: "/master-menu/parcel-rates",
      },
    };
  })(),
};

export default RouteUrl;
