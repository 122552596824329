import React from "react";
import { useParams } from "react-router-dom";
import BaseLayout from "../panel/layouts/BaseLayout";
import DataViewComp from "../../components/shared/data-info/DataViewComp";
import { useDispatch, useSelector } from "react-redux";
import branchAction from "../../redux/slice/branch/action";

const BranchViewPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const branchState = useSelector((state) => state.branch);
  const [info, setInfo] = React.useState([]);
  //   const
  //   console.log(params);

  React.useEffect(() => {
    dispatch(branchAction.getBranch(params.id));
  }, []);

  React.useEffect(() => {
    setInfo([
      { label: "Name", value: branchState?.view?.name },
      { label: "Address", value: branchState?.view?.branch_address?.address },
      { label: "State", value: branchState?.view?.branch_address?.state?.name },
      {
        label: "District",
        value: branchState?.view?.branch_address?.district?.name,
      },
      {
        label: "City",
        value: branchState?.view?.branch_address?.city?.office_name,
      },
      { label: "Pin", value: branchState?.view?.branch_address?.pin },
    ]);
  }, [branchState.view]);

  return (
    <BaseLayout pageTitle={"Branch View"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4>Branch Info</h4>
            </div>
            <div className="card-body">
              <DataViewComp dataInfo={info} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default BranchViewPage;
