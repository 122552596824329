import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const RoleGuardRoute = ({roles=[]}) => {
  // console.log({roles});
  const auth = useSelector((state) => state.auth);
  // console.log('INFO authRoutes',auth.token);
  if(!auth.token){
    return <Navigate to='/login' />
  }
  if(auth?.roles){
    let roleFound=<Navigate to='/login' />
    roles.map(role=>{
      if(auth.roles.includes(role)){
        roleFound=<Outlet/>
      }
    })
    return roleFound
    
  }
  

    return <Outlet/> ;
  

};

export default RoleGuardRoute;
