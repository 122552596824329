const makeSelectInputOptions = (options = [], labelKeyName, valueKeyName) => {
  return options.map((item) => {
    // if labelkeyName is function
    let labelVal =
      typeof labelKeyName === "function"
        ? labelKeyName(item)
        : item[labelKeyName];

    let valueVal =
      typeof labelKeyName === "function"
        ? valueKeyName(item)
        : item[valueKeyName];

    return { label: labelVal, value: valueVal };
  });
};

export { makeSelectInputOptions };
