import React from "react";
import BaseLayout from "../panel/layouts/BaseLayout";
import { Link } from "react-router-dom";
import FlipDataTable from "../../components/flip-table/FlipDataTable";
import useFlipDataTableActionBtn from "../../hooks/useFlipDataTableActionBtn";
import RouteUrl from "../../_config/constants/route_url";

const ParcelIndexPage = () => {
  const columns = useFlipDataTableActionBtn([], []);

  return (
    <BaseLayout pageTitle={"Parcel"}>
      <div className="row mb-3">
        <div className="col-12">
          <Link className="btn btn-primary" to={RouteUrl.parcel.create}>
            Add
          </Link>
        </div>
      </div>
      <FlipDataTable columns={columns} data={[]} />
    </BaseLayout>
  );
};

export default ParcelIndexPage;
