import flipAlert from "../../utils/alert/flipAlert";
import getFlipAxiosInstance, {
  axiosResponseError,
} from "../../utils/flipAxios";

const axiosInstance = getFlipAxiosInstance();

const prefixUrl = "/gst-rates";

const list = async () => {
  try {
    const res = await axiosInstance.get(prefixUrl);

    return res.data;
  } catch (error) {
    console.log("parcel list error", error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const store = async (data) => {
  try {
    const res = await axiosInstance.post(`${prefixUrl}`, data);

    return res.data;
  } catch (error) {
    console.log("parcel add error", error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const update = async ({ id, data }) => {
  try {
    const res = await axiosInstance.put(`${prefixUrl}/${id}`, data);

    return res.data;
  } catch (error) {
    console.log("parcel update error", error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const show = async (id) => {
  try {
    const res = await axiosInstance.get(`${prefixUrl}/${id}`);

    return res.data;
  } catch (error) {
    console.log("parcel add error", error);
    flipAlert.showError(axiosResponseError(error));
  }
};
const isActive = async (id) => {
  try {
    const res = await axiosInstance.get(`${prefixUrl}/is-active/${id}`);

    return res.data;
  } catch (error) {
    console.log("parcel add error", error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const gstRateService = {
  list,
  store,
  update,
  show,
  isActive,
};

export default gstRateService;
