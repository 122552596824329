import React from "react";
import DataViewComp from "../../../components/shared/data-info/DataViewComp";
import BaseLayout from "../../panel/layouts/BaseLayout";
import parcelTypeService from "../../../services/master/parcel-type.service";
import { useParams } from "react-router-dom";

const ParcelTypeViewPage = () => {
  const params = useParams();
  const [parcelType, setParcelType] = React.useState({});
  const [viewInfo, setViewInfo] = React.useState([]);

  React.useEffect(() => {
    parcelTypeService.show(params.id).then((res) => {
      setParcelType(res.parcelType);
    });
  }, []);

  React.useEffect(() => {
    setViewInfo([
      { label: "Name", value: parcelType.name },
      { label: "Logo", value: parcelType.logo_url, type: "image" },
      { label: "Description", value: parcelType.description },
    ]);
  }, [parcelType]);

  return (
    <BaseLayout pageTitle={"ParcelType View"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <DataViewComp dataInfo={viewInfo} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ParcelTypeViewPage;
