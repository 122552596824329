class BranchMapper {
  static createRequest(reqBody) {
    return {
      name: reqBody.name,
      address: reqBody.address,
      state_id: reqBody.state,
      district_id: reqBody.district,
      city_id: reqBody.city,
      pin: reqBody.pin,
    };
  }
}

export default BranchMapper;
