import flipAlert from "../utils/alert/flipAlert"
import getFlipAxiosInstance, { axiosResponseError } from "../utils/flipAxios"
import flipLog from "../utils/logs/log.util"


const axiosInstance = getFlipAxiosInstance()


const list = async () => {
    try {
        const res = await axiosInstance.get('/party')

        return res.data
    } catch (error) {
        console.log('party list error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}
const add = async (data) => {
    try {
        const res = await axiosInstance.post('/party', data)

        return res.data
    } catch (error) {
        console.log('party add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const edit = async ({id,data}) => {
    try {
        const res = await axiosInstance.put(`/party/${id}`, data)

        return res.data
    } catch (error) {
        console.log('party add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const view = async (id) => {
    try {
        const res = await axiosInstance.get(`/party/${id}`)

        return res.data
    } catch (error) {
        console.log('party add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}


const userPartyService = {
    add,
    list,
    edit,
    view
}
export default userPartyService