import React from "react";
import { Link } from "react-router-dom";
import RouteUrl from "../../../_config/constants/route_url";
import { useSelector } from "react-redux";

const SidebarPage = () => {
  const authState = useSelector((state) => state.auth);

  const [roles, setRoles] = React.useState(
    authState.user.roles.reduce((acm, item) => {
      acm.push(item.name);
      return acm;
    }, [])
  );

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link " to={RouteUrl.dashboard}>
              <i className="bi bi-grid" />
              <span>Dashboard</span>
            </Link>
          </li>
          {/* End Dashboard Nav */}

          {roles.includes("superadmin") && (
            // Branch Related Menu
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#branch-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <i className="bi bi-menu-button-wide" />
                <span>Branch Related</span>
                <i className="bi bi-chevron-down ms-auto" />
              </a>
              <ul
                id="branch-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={RouteUrl.branch.index}>
                    <i className="bi bi-circle" />
                    <span>Branches</span>
                  </Link>
                </li>
                <li>
                  <Link to={RouteUrl.branchManager.index}>
                    <i className="bi bi-circle" />
                    <span>Managers</span>
                  </Link>
                </li>
                <li>
                  <Link to={RouteUrl.assignedManager.index}>
                    <i className="bi bi-circle" />
                    <span>Assigned Managers</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {/* End Branch Nav */}

          {/* { Start Master Nav} */}
          {roles.includes("superadmin") && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#master-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <i className="bi bi-menu-button-wide" />
                <span>Master Data</span>
                <i className="bi bi-chevron-down ms-auto" />
              </a>
              <ul
                id="master-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={RouteUrl.parcelType.index}>
                    <i className="bi bi-circle" />
                    <span>Parcel Type</span>
                  </Link>
                </li>
                <li>
                  <Link to={RouteUrl.parcelDeliveryStatusType.index}>
                    <i className="bi bi-circle" />
                    <span>Parcel DeliverStatus Type</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {/* { End master Nav} */}

          {/* branch-manager menu */}
          {roles.includes("branch-manager") && (
            <>
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#branch-manager-nav"
                  data-bs-toggle="collapse"
                  href="#"
                >
                  <i className="bi bi-menu-button-wide" />
                  <span>Party</span>
                  <i className="bi bi-chevron-down ms-auto" />
                </a>
                <ul
                  id="branch-manager-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  <li>
                    <Link to={RouteUrl.party.index}>
                      <i className="bi bi-circle" />
                      <span>List</span>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* parcel menu */}
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#parcel-nav"
                  data-bs-toggle="collapse"
                  href="#"
                >
                  <i className="bi bi-menu-button-wide" />
                  <span>Parcel</span>
                  <i className="bi bi-chevron-down ms-auto" />
                </a>
                <ul
                  id="parcel-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  <li>
                    <Link to={RouteUrl.parcel.index}>
                      <i className="bi bi-circle" />
                      <span>List</span>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* parcel rates menu */}
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#parcel-rate-nav"
                  data-bs-toggle="collapse"
                  href="#"
                >
                  <i className="bi bi-menu-button-wide" />
                  <span>MasterMenu</span>
                  <i className="bi bi-chevron-down ms-auto" />
                </a>
                <ul
                  id="parcel-rate-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  <li>
                    <Link to={RouteUrl.managerSection.parcelRate.edit}>
                      <i className="bi bi-circle" />
                      <span>parcelRates</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          )}
          {/* End Branch-Manager Nav */}

          <li className="nav-heading">Pages</li>
          <li className="nav-item">
            <a className="nav-link collapsed" href="users-profile.html">
              <i className="bi bi-person" />
              <span>Profile</span>
            </a>
          </li>
          {/* End Profile Page Nav */}
        </ul>
      </aside>
    </>
  );
};

export default SidebarPage;
