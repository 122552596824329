import { createAsyncThunk } from "@reduxjs/toolkit";
import getFlipAxiosInstance from "../../../utils/flipAxios";
import { BACKEND_API } from "../../../_config/constants/backend_api";

const axiosInstance=getFlipAxiosInstance()
const getStates=createAsyncThunk('getStates',async()=>{
    
    try {
        const res=await axiosInstance.post(BACKEND_API.URL+'/states')
        return res.data
    } catch (error) {
        console.log('Error','locationACtion',error);
    }
})

const getDistricts=createAsyncThunk('getDistricts',async(location_state_id)=>{
    
    try {
        const res=await axiosInstance.post(BACKEND_API.URL+'/districts',{location_state_id})
        return res.data
    } catch (error) {
        console.log('Error','locationACtion',error);
    }
})
const getCities=createAsyncThunk('getCities',async(district_id)=>{
    
    try {
        const res=await axiosInstance.post(BACKEND_API.URL+'/cities',{district_id})
        return res.data
    } catch (error) {
        console.log('Error','locationACtion',error);
    }
})

// -----------------------
const locationAction={
    getStates,
    getDistricts,
    getCities
}

export default locationAction