import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BaseLayout from "../pages/panel/layouts/BaseLayout";
import LoginPage from "../pages/admin/auth/LoginPage";
import DashboardPage from "../pages/DashboardPage";
import AdminRoute from "./protected/AdminRoute";
import TestPage from "../pages/TestPage";
import GuestRoute from "./_guard/GuestRoute";
import PrivateRoutes from "./_guard/PrivateRoutes";
import AuthRoutes from "./_guard/AuthRoutes";
import {
  BranchAddPage,
  BranchEditPage,
  BranchIndexPage,
  BranchViewPage,
} from "../pages/branch";
import {
  BranchManagerAddPage,
  BranchManagerEditPage,
  BranchManagerIndexPage,
  BranchManagerViewPage,
} from "../pages/branch-manager";
import {
  AssignedManagerIndexPage,
  AssignedManagerAddPage,
  AssignedManagerEditPage,
} from "../pages/assigned-manager";
import {
  PartyAddPage,
  PartyEditPage,
  PartyIndexPage,
  PartyViewPage,
} from "../pages/party";
import {
  ParcelTypeIndexPage,
  ParcelTypeAddPage,
  ParcelTypeEditPage,
  ParcelTypeViewPage,
} from "../pages/master-section/parcel-types";
import RoleGuardRoute from "./_guard/RoleGuardRoute";
import { ParcelAddPage, ParcelIndexPage } from "../pages/parcel";
import { ManagerParcelRateEditPage } from "../pages/branch-manager/parcel-rates";
import { ParcelDeliveryStatusTypeIndexPage } from "../pages/master-section/parcel-delivery-status-types";

const IndexRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="test" element={<TestPage />} />
        <Route
          path="login"
          element={
            <GuestRoute>
              <LoginPage />
            </GuestRoute>
          }
        />
        <Route element={<AuthRoutes />}>
          <Route path="" element={<BaseLayout> </BaseLayout>} />
          <Route path="dashboard" element={<DashboardPage />} />

          <Route element={<RoleGuardRoute roles={["superadmin"]} />}>
            <Route path="branch">
              <Route path="" element={<BranchIndexPage />} />
              <Route path="create" element={<BranchAddPage />} />
              <Route path=":id" element={<BranchViewPage />} />
              <Route path=":id/edit" element={<BranchEditPage />} />
            </Route>

            <Route path="branch-manager">
              <Route path="" element={<BranchManagerIndexPage />} />
              <Route path="create" element={<BranchManagerAddPage />} />
              <Route path=":id" element={<BranchManagerViewPage />} />
              <Route path=":id/edit" element={<BranchManagerEditPage />} />
            </Route>

            <Route path="assigned-manager">
              <Route path="" element={<AssignedManagerIndexPage />} />
              <Route path="create" element={<AssignedManagerAddPage />} />
              {/* <Route path=":id" element={<AssignedManagerViewPage />} /> */}
              <Route path=":id/edit" element={<AssignedManagerEditPage />} />
            </Route>

            <Route path="parcel-types">
              <Route path="" element={<ParcelTypeIndexPage />} />
              <Route path="create" element={<ParcelTypeAddPage />} />
              <Route path=":id/edit" element={<ParcelTypeEditPage />} />
              <Route path=":id" element={<ParcelTypeViewPage />} />
            </Route>

            <Route path="parcel-delivery-status-types">
              <Route path="" element={<ParcelDeliveryStatusTypeIndexPage />} />
              {/* 
              <Route path="create" element={<ParcelTypeAddPage />} />
              <Route path=":id/edit" element={<ParcelTypeEditPage />} />
              <Route path=":id" element={<ParcelTypeViewPage />} /> 
              */}
            </Route>
          </Route>

          {/*  */}
          <Route path="party">
            <Route path="" element={<PartyIndexPage />} />
            <Route path="create" element={<PartyAddPage />} />
            <Route path=":id/edit" element={<PartyEditPage />} />
            <Route path=":id" element={<PartyViewPage />} />
          </Route>

          <Route path="parcel">
            <Route path="" element={<ParcelIndexPage />} />
            <Route path="create" element={<ParcelAddPage />} />
            <Route path=":id/edit" element={<PartyEditPage />} />
            <Route path=":id" element={<PartyViewPage />} />
          </Route>

          <Route path="/master-menu">
            <Route element={<RoleGuardRoute roles={["branch-manager"]} />}>
              <Route path="parcel-rates">
                <Route path="" element={<ManagerParcelRateEditPage />} />
              </Route>
            </Route>
          </Route>

          <Route path="admin/*" element={<AdminRoute />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default IndexRoute;
