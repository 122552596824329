
import {createSlice} from '@reduxjs/toolkit'
import authAction, { loginAction } from './action'

const initialState={
    isLoading:false,
    roles:[],
    permissions:[],
    token:'',
    user:{},
}

const authSlice=createSlice({
    name:'auth',
    initialState,

    extraReducers:(builder)=>{
        builder.addCase(authAction.login.fulfilled,(state,action)=>{
            state.isLoading=false
            // console.log('INFO','authSlice',action);
            state.token=action?.payload?.token
            state.roles=action?.payload?.roles
            state.permissions=action?.payload?.permissions
            state.user=action?.payload?.user
        })

        // logout
        builder.addCase(authAction.logout.fulfilled,(state,action)=>{
            // console.log(action.payload);
            state.token=''
            state.user={}
            state.roles=[]
            state.permissions=[]
        })
        builder.addCase(authAction.logout.rejected,(state,action)=>{
            console.log('INFO logout rejected',action);

            if(action?.payload.status==401){
                state.token=''
                state.user={}
                state.roles=[]
                state.permissions=[]
            }
        })


    }
    
})

export const authReducer=authSlice.reducer

