import React from 'react'
import DataViewComp from '../../components/shared/data-info/DataViewComp'
import BaseLayout from '../panel/layouts/BaseLayout'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import branchManagerAction from '../../redux/slice/branch-manager/action';

const BranchManagerViewPage = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.branchManager);
  const [info, setInfo] = React.useState([]);

  React.useEffect(() => {
    dispatch(branchManagerAction.view(params.id));
  }, []);

  React.useEffect(() => {
    setInfo([
      { label: "Username", value: stateData.view?.username },
      { label: "Name", value: stateData.view?.name },
      { label: "Email", value: stateData.view?.email },
      { label: "Mobile", value: stateData.view?.mobile },
    ]);
  },[stateData.view])


  return (
    <BaseLayout pageTitle={"BranchManager View"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4>BranchManger View</h4>
            </div>
            <div className="card-body">
              <DataViewComp dataInfo={info} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default BranchManagerViewPage