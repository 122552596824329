import React from "react";
import BaseLayout from "../panel/layouts/BaseLayout";
import AddressSetInputComp from "../../components/shared/forms/AddressSetInputComp";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FlipInput,
  FlipInputTextArea,
} from "../../components/shared/forms/inputs";
import { userPartyService } from "../../services";
import { useNavigate } from "react-router-dom";
import flipAlert from "../../utils/alert/flipAlert";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().nullable(),
    mobile: yup.string().required(),
    address: yup.string().required(),
    gstin_no: yup.string().nullable(),
    company_name: yup.string().nullable(),

    // country: yup.string().required(),
    state: yup.object().required(),
    district: yup.object().required(),
    city: yup.object().required(),
    pin: yup.string().required(),
  })
  .required();

const PartyAddPage = () => {
  const navigate = useNavigate();

  // form
  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const fd = {
      ...data,
      city_id: data.city?.value,
      state_id: data.state?.value,
      district_id: data.district?.value,
    };
    delete fd.city;
    delete fd.state;
    delete fd.district;

    console.log({ fd });

    const resData = await userPartyService.add(fd);
    if (resData) {
      console.log({ resData });
      flipAlert.showSuccess("data add successful");
      navigate("/party");
    }
  };

  return (
    <BaseLayout pageTitle={"Party Add"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="row g-2">
                {/* party name */}
                <FlipInput
                  inputType="text"
                  labelTitle={"Name"}
                  labelRequired
                  register={register("name")}
                  errors={errors}
                />

                {/* party Email */}
                <FlipInput
                  inputType="text"
                  labelTitle={"Email (optional)"}
                  register={register("email")}
                  errors={errors}
                />

                {/* party mobile */}
                <FlipInput
                  inputType="text"
                  labelTitle={"Mobile"}
                  labelRequired
                  register={register("mobile")}
                  errors={errors}
                />
                {/* party gstin */}
                <FlipInput
                  inputType="text"
                  labelTitle={"GSTIN (optional)"}
                  register={register("gstin_No")}
                  errors={errors}
                />
                {/* party company_name */}
                <FlipInput
                  inputType="text"
                  labelTitle={"Company Name (optional)"}
                  register={register("company_name")}
                  errors={errors}
                />

                {/* party address */}
                <FlipInputTextArea
                  inputType="text"
                  labelTitle={"Address"}
                  labelRequired
                  register={register("address")}
                  errors={errors}
                  row={3}
                />

                <div className="col-12">
                  <AddressSetInputComp
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                </div>
                {/* submit */}
                <div className="col-12">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default PartyAddPage;
