import { createSlice } from "@reduxjs/toolkit"
import branchManagerAction from "./action"
import flipAlert from "../../../utils/alert/flipAlert"


const initialState={
    isLoading: false,
  list: [],
  view: {},
}

const branchManagerSlice=createSlice({
    name:'branchManager',
    initialState,
    extraReducers:(builder)=>{
        // branch-mananger list
        builder.addCase(branchManagerAction.list.fulfilled,(state,action)=>{
            state.isLoading=false
            if (action?.payload?.branchManagers){

                state.list=[...action?.payload?.branchManagers]
            }
        })
        builder.addCase(branchManagerAction.list.pending,(state,action)=>{
            state.isLoading=true
            
        })
        builder.addCase(branchManagerAction.list.rejected,(state,action)=>{
            // state.isLoading=true
            
        })
        // -----------------
        // branch-mananger view
        builder.addCase(branchManagerAction.view.fulfilled,(state,action)=>{
            state.isLoading=false
            state.view=action.payload.branchManager
            // flipAlert.showSuccess("branchManager add successfully");
        })
        builder.addCase(branchManagerAction.view.pending,(state,action)=>{
            state.isLoading=true
            
        })
        builder.addCase(branchManagerAction.view.rejected,(state,action)=>{
            state.isLoading=false
            
        })

        // ---------------
        // branch-mananger add
        builder.addCase(branchManagerAction.add.fulfilled,(state,action)=>{
            state.isLoading=false
            // state.view={...action.payload.branchManager}
            state.list.push(action.payload.user)
        })
        builder.addCase(branchManagerAction.add.pending,(state,action)=>{
            state.isLoading=true
            
        })
        builder.addCase(branchManagerAction.add.rejected,(state,action)=>{
            state.isLoading=false
            flipAlert.showError(action.error.message);
            
        })

        // ---------------
        // branch-mananger edit
        builder.addCase(branchManagerAction.edit.fulfilled,(state,action)=>{
            state.isLoading=false
            // state.view={...action.payload.branchManager}
            // state.list.push(action.payload.user)
            flipAlert.showSuccess('branch-manger updated successfully');
        })
        builder.addCase(branchManagerAction.edit.pending,(state,action)=>{
            state.isLoading=true
            
        })
        builder.addCase(branchManagerAction.edit.rejected,(state,action)=>{
            state.isLoading=false
            flipAlert.showError(action.error.message);
            
        })

        // ---------------
    }
})

export const branchManagerReducer = branchManagerSlice.reducer;