import React from "react";
import BaseLayout from "../panel/layouts/BaseLayout";
import {
  FlipInput,
  FlipInputTextArea,
  FlipSelect,
} from "../../components/shared/forms/inputs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yup } from "../../utils/pkgs";
import AddressSetInputComp from "../../components/shared/forms/AddressSetInputComp";
import {
  managerParcelRateService,
  parcelService,
  userBranchService,
  userPartyService,
} from "../../services";
import { makeSelectInputOptions } from "../../utils/form-inputs/form-inputs.util";
import { useSelector } from "react-redux";
import { ParcelMapper } from "../../_http/mapper";
import {
  gstRateService,
  parcelTypeService,
  transportTypeService,
} from "../../services/master";
import flipAlert from "../../utils/alert/flipAlert";

const schema = yup
  .object({
    context: yup.string().required(),
    customer: yup.object().required(),
    parcelType: yup.object().required(),
    transportType: yup.object().required(),
    noOfBoxes: yup.string().required(),
    toBranch: yup.object().required(),
    weight: yup.string().required(),
    valuation: yup.string().required(),
    weight_rate: yup.string().required(),
    valuation_rate: yup.string().required(),
    gst_rate: yup.object().required(),
    payment_type: yup.object().required(),
    discount_amount: yup.string().required(),
    pickupAddress: yup
      .object()
      .shape({
        address: yup.string().required(),
        state: yup.object().required(),
        district: yup.object().required(),
        city: yup.object().required(),
        pin: yup.string().required(),
      })
      .required(),
    dropoffAddress: yup
      .object()
      .shape({
        address: yup.string().required(),
        state: yup.object().required(),
        district: yup.object().required(),
        city: yup.object().required(),
        pin: yup.string().required(),
      })
      .required(),
  })
  .required();

const ParcelAddPage = () => {
  const [parties, setParties] = React.useState([]);
  const [parcelTypes, setParcelTypes] = React.useState([]);
  const [transportTypes, setTransportTypes] = React.useState([]);
  const [gstRates, setGstRates] = React.useState([]);
  const [paymentTypes, setPaymentTypes] = React.useState([]);
  const [userBranches, setUserBranches] = React.useState([]);
  const [parcelRates, setParcelRates] = React.useState({});
  const [parcelCost, setParcelCost] = React.useState({ discount_amount: 0 });

  const authState = useSelector((state) => state.auth);

  React.useEffect(() => {
    managerParcelRateService.show().then((res) => {
      if (res && res.managerParcelRate) {
        setParcelRates(res?.managerParcelRate);
        setParcelCost({
          ...parcelCost,
          weight_rate: res?.managerParcelRate.weight_rate,
          valuation_rate: res?.managerParcelRate?.valuation_rate,
        });
      }
    });
  }, []);

  // form
  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      discount_amount: parcelCost.discount_amount,
      // weight_rate: parcelCost.weight_rate,
      // valuation_rate: parcelCost.valuation_rate,
    },
    values: {
      weight_rate: parcelCost.weight_rate,
      valuation_rate: parcelCost.valuation_rate,
    },
  });

  React.useEffect(() => {
    userPartyService.list().then((res) => {
      setParties(res?.parties);
    });
    parcelTypeService.list().then((res) => {
      setParcelTypes(res?.parcelTypes);
    });
    transportTypeService.list().then((res) => {
      setTransportTypes(res?.transportTypes);
    });
    gstRateService.list().then((res) => {
      setGstRates(res?.gstRates);
      const fval = res?.gstRates[0];
    });
    setPaymentTypes([
      { label: "PAID", value: "PAID" },
      { label: "TO_PAY", value: "TO_PAY" },
    ]);

    userBranchService.list().then((res) => {
      if (res?.branches) {
        if (authState?.user?.branches) {
          setUserBranches(
            res?.branches.filter(
              (item) => item.id !== authState?.user?.branches[0]?.id
            )
          );
        } else {
          setUserBranches(res.branches);
        }
      }
    });
  }, []);

  // cost calculation
  React.useEffect(() => {
    if (
      parcelCost.weight &&
      parcelCost.valuation &&
      parcelCost.weight_rate &&
      parcelCost.valuation_rate
    ) {
      console.log(parcelCost);
      const weightCost = parcelCost.weight * parcelCost.weight_rate;
      const valuationCost =
        parcelCost.valuation * (parcelCost.valuation_rate / 100);
      const cost = weightCost + valuationCost;

      const gstAmount = (cost * parcelCost.gst_rate) / 100;
      const costWithGst = cost + gstAmount ?? 0;
      const finalCost = costWithGst - parcelCost.discount_amount;
      setParcelCost({
        ...parcelCost,
        weight_cost: weightCost,
        valuation_cost: valuationCost,
        cost: cost,
        gst_amount: gstAmount,
        cost_with_gst: costWithGst,
        final_cost: finalCost,
      });
    }
  }, [
    parcelCost.weight,
    parcelCost.valuation,
    parcelCost.weight_rate,
    parcelCost.valuation_rate,
    parcelCost.gst_rate,
    parcelCost.discount_amount,
  ]);

  // form submit
  const onSubmit = (data) => {
    console.log({ data });
    const tempData = { ...data };
    tempData.customer = tempData.customer.value;
    tempData.parcelType = tempData.parcelType.value;
    tempData.toBranch = tempData.toBranch.value;

    // console.log(tempData);
    const dataMapper = ParcelMapper.createRequest(data);
    console.log({ dataMapper });
    parcelService.add(dataMapper).then((res) => {
      flipAlert.showSuccess("data added successfully");
    });
  };

  return (
    <BaseLayout pageTitle={"Parcel Add"}>
      <form className="row g-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <FlipInputTextArea
                  labelRequired
                  labelTitle={"Context of the Parcel"}
                  register={register("context")}
                  control={control}
                  errors={errors}
                  onChange={(data) => {}}
                  // value={userInput.state}
                />
              </div>
              <div className="col-lg-4">
                <FlipSelect
                  labelRequired
                  labelTitle={"Customer"}
                  register={register("customer")}
                  control={control}
                  options={makeSelectInputOptions(parties, "name", "id")}
                  errors={errors}
                  onChange={(data) => {}}
                  // value={userInput.state}
                />
              </div>
              <div className="col-lg-4">
                <FlipSelect
                  labelRequired
                  labelTitle={"ParcelType"}
                  register={register("parcelType")}
                  control={control}
                  options={makeSelectInputOptions(parcelTypes, "name", "id")}
                  errors={errors}
                  onChange={(data) => {}}
                  // value={userInput.state}
                />
              </div>
              <div className="col-lg-4">
                <FlipSelect
                  labelRequired
                  labelTitle={"Transport Type"}
                  register={register("transportType")}
                  control={control}
                  options={makeSelectInputOptions(transportTypes, "name", "id")}
                  errors={errors}
                  onChange={(data) => {}}
                  // value={userInput.state}
                />
              </div>
              <div className="col-lg-4">
                <FlipInput
                  inputType="text"
                  labelTitle={"No of Boxes"}
                  labelRequired
                  register={register("noOfBoxes")}
                  errors={errors}
                />
              </div>
              <div className="col-lg-4">
                <FlipSelect
                  labelRequired
                  labelTitle={"To Branch"}
                  register={register("toBranch")}
                  control={control}
                  options={makeSelectInputOptions(userBranches, "name", "id")}
                  errors={errors}
                  onChange={(data) => {}}
                  // value={userInput.state}
                />
              </div>
            </div>
          </div>
        </div>
        {/* calculation price section */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4">
                <FlipInput
                  inputType="number"
                  labelTitle={"Weight Rate(per KG)"}
                  labelRequired
                  register={register("weight_rate")}
                  errors={errors}
                  defaultValue={parcelRates.weight_rate}
                  onKeyUp={(e) =>
                    setParcelCost({
                      ...parcelCost,
                      weight_rate: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-4">
                <FlipInput
                  inputType="number"
                  labelTitle={"Valuation Rate (in %)"}
                  labelRequired
                  register={register("valuation_rate")}
                  errors={errors}
                  defaultValue={parcelRates.valuation_rate}
                  onKeyUp={(e) =>
                    setParcelCost({
                      ...parcelCost,
                      valuation_rate: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-lg-4">
                <FlipInput
                  inputType="number"
                  labelTitle={"Weight (KG)"}
                  labelRequired
                  register={register("weight")}
                  errors={errors}
                  onKeyUp={(e) =>
                    setParcelCost({ ...parcelCost, weight: e.target.value })
                  }
                />
              </div>
              <div className="col-lg-4">
                <FlipInput
                  inputType="number"
                  labelTitle={"Valuation (Rs)"}
                  labelRequired
                  register={register("valuation")}
                  errors={errors}
                  onKeyUp={(e) =>
                    setParcelCost({ ...parcelCost, valuation: e.target.value })
                  }
                />
              </div>
              <div className="col-lg-4">
                <FlipSelect
                  labelRequired
                  labelTitle={"Gst(%)"}
                  register={register("gst_rate")}
                  control={control}
                  options={makeSelectInputOptions(gstRates, "name", "rate")}
                  errors={errors}
                  onChange={(data) =>
                    setParcelCost({ ...parcelCost, gst_rate: data?.value })
                  }
                />
              </div>
              <div className="col-lg-4">
                <FlipInput
                  inputType="number"
                  labelTitle={"Discount (Rs.)"}
                  labelRequired
                  register={register("discount_amount")}
                  errors={errors}
                  onKeyUp={(e) =>
                    setParcelCost({
                      ...parcelCost,
                      discount_amount: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-lg-4">
                <FlipSelect
                  labelRequired
                  labelTitle={"Payment Type"}
                  register={register("payment_type")}
                  control={control}
                  options={paymentTypes}
                  errors={errors}
                />
              </div>

              <div className="col-lg-12">
                <div className="row ">
                  <div className="col-lg-4 bg-info py-2">
                    <table className="table table-sm table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Cost</th>
                          <td>
                            {parcelCost?.cost ?? "-----"}
                            <span className="ms-1">
                              ({parcelCost?.weight_cost ?? ""}
                              {parcelCost?.valuation_cost ? "+" : ""}
                              {parcelCost?.valuation_cost ?? ""})
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Gst Amnt</th>
                          <td>{parcelCost?.gst_amount ?? "-----"}</td>
                        </tr>
                        <tr>
                          <th>Final Cost</th>
                          <th>
                            <span className="me-1"> Rs.</span>
                            <span>{parcelCost?.final_cost ?? "-----"}</span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* pickup address */}
        <div className="card">
          <div className="card-header">
            <p>Pickup Address</p>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <FlipInputTextArea
                  inputType="text"
                  labelTitle={"Address"}
                  labelRequired
                  register={register("pickupAddress.address")}
                  errors={errors}
                />
              </div>

              <AddressSetInputComp
                divSize={{ all: "col-lg-4" }}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                inputNames={{
                  state: "pickupAddress.state",
                  district: "pickupAddress.district",
                  city: "pickupAddress.city",
                  pin: "pickupAddress.pin",
                }}
              />
            </div>
          </div>
        </div>
        {/* Dropoff address */}
        <div className="card">
          <div className="card-header">
            <p>Dropoff Address</p>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <FlipInputTextArea
                  inputType="text"
                  labelTitle={"Address"}
                  labelRequired
                  register={register("dropoffAddress.address")}
                  errors={errors}
                />
              </div>

              <AddressSetInputComp
                divSize={{ all: "col-lg-4" }}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                inputNames={{
                  state: "dropoffAddress.state",
                  district: "dropoffAddress.district",
                  city: "dropoffAddress.city",
                  pin: "dropoffAddress.pin",
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="offset-lg-3 col-lg-6">
            <div className="card">
              <div className="card-body">
                {/* submit */}
                <div className="col-12 mt-1">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseLayout>
  );
};

export default ParcelAddPage;
