import React from "react";
import BaseLayout from "../panel/layouts/BaseLayout";
import { Link } from "react-router-dom";
import RouteUrl from "../../_config/constants/route_url";
import { useDispatch, useSelector } from "react-redux";
import branchManagerAction from "../../redux/slice/branch-manager/action";
import useFlipDataTableActionBtn from "../../hooks/useFlipDataTableActionBtn";
import FlipDataTable from "../../components/flip-table/FlipDataTable";
import moment from "moment";


const BranchManagerIndexPage = () => {

    const branchManagerState=useSelector(state=>state.branchManager)

    const dispatch=useDispatch()


    React.useEffect(()=>{
        dispatch(branchManagerAction.list())
    },[])
    



    const columns = useFlipDataTableActionBtn([
        {
            name: 'Username',
            selector: (row) => {return row.username},
        } ,
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
           name: 'Mobile',
           selector: row => row.mobile,
        },
        {
           name: 'Created Date',
           selector: row => moment(row.created_at).format('DD/MM/YYYY'),
        }
    ], 
    [
        {
          type: "view",
          actionUrl: "/branch-manager/:id",
          actionUrlParamObj: { id: "id" },
        },
        {
          type: "edit",
          actionUrl: "/branch-manager/:id/edit",
          actionUrlParamObj: { id: "id" },
        },
        {
          type: "delete",
          // actionUrl: "/branch/:id",
          // actionUrlParamObj: { id: "id" },
          callback: ()=>{},
        },
      ]);
    
    const data = [
          {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

  return (
    <BaseLayout pageTitle={"Branch Manager"}>
        <div className="row mb-3">
            <div className="col-12">
                <Link className="btn btn-primary" to={RouteUrl.branchManager.create}>Add</Link>
            </div>
        </div>
    <FlipDataTable
    columns={columns}
    data={branchManagerState.list}
    />
    

    </BaseLayout>
  );
};

export default BranchManagerIndexPage;
