import React, { useEffect, useState } from "react";
import BaseLayout from "../panel/layouts/BaseLayout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import assignedManagerAction from "../../redux/slice/assigned-manager/action";
import FlipSelect from "../../components/shared/forms/inputs/FlipSelect";
import branchAction from "../../redux/slice/branch/action";
import branchManagerAction from "../../redux/slice/branch-manager/action";

const schema = yup
  .object({
    user_id: yup.object().required(),
    branch_id: yup.object().required(),
  })
  .required();

const AssignedManagerAddPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const branchState = useSelector((state) => state.branch);
  const branchManagerState = useSelector((state) => state.branchManager);
  const [branchOptions, setBranchOptions] = useState([]);
  const [managerNameOptions, setManagerNameOptions] = useState([]);

  React.useEffect(() => {
    dispatch(branchAction.getAllBranch());
  }, []);

  React.useEffect(() => {
    dispatch(branchManagerAction.list());
  }, []);

  React.useEffect(() => {
    const branchOpts = branchState.list.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setBranchOptions(branchOpts);
  }, []);

  React.useEffect(() => {
    const managerNameOpts = branchManagerState.list.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setManagerNameOptions(managerNameOpts);
  }, []);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    //    data.user_id=data.user_id.value;
    //    data.branch_id=data.branch_id.value;
    console.log(data);

    // const reqBody = {
    //     ...data,
    //     user_id: data?.user_id?.value,
    //     branch_id: data?.branch_id?.value,
    // };
    // const reqBodyMapData = AssignedMapper.createRequest(reqBody);
    dispatch(assignedManagerAction.add(data))
      .unwrap()
      .then((result) => {
        console.log({ result });
        navigate("/assigned-manager");
      })
      .catch((error) => {});
  };

  return (
    <BaseLayout pageTitle={"Assigned Manager Add"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                <div className="col-12">
                  <FlipSelect
                    labelTitle={"Branch Manager Name"}
                    register={register("user_id")}
                    control={control}
                    options={managerNameOptions}
                    errors={errors}
                  />
                </div>

                <div className="col-12">
                  <FlipSelect
                    labelTitle={"Branch Name"}
                    register={register("branch_id")}
                    control={control}
                    options={branchOptions}
                    errors={errors}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AssignedManagerAddPage;
