import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const AuthRoutes = () => {
  const auth = useSelector((state) => state.auth);
  // console.log('INFO authRoutes',auth.token);
  if(!auth.token){
    return <Navigate to='/login' />
  }else{

    return <Outlet/> ;
  }

};

export default AuthRoutes;
