import { createAsyncThunk } from "@reduxjs/toolkit";
import getFlipAxiosInstance, { axiosErrorThrow } from "../../../utils/flipAxios";
import flipLog from "../../../utils/logs/log.util";
import { BACKEND_API } from "../../../_config/constants/backend_api";

const axiosInstance = getFlipAxiosInstance();

const list = createAsyncThunk("list",async()=>{
    try {
        const res = await axiosInstance.get("/assigned-manager");
        return res.data;
    }catch (error) {
        flipLog.InfoDanger("assignedManager action",error);
        axiosErrorThrow(error);
    }
});

const add = createAsyncThunk("add",async(data)=>{
    try {
       const res = await axiosInstance.post("/assigned-manager",data); 
       console.log('assigned-manager action',{res:res});
       return res.data;
    } catch (error) {
        flipLog.InfoDanger("branchManager action add", error);
        axiosErrorThrow(error);
    }            
});

const view = createAsyncThunk("view",async()=>{
    try {
        const res = await axiosInstance.get("/assigned-manager");
        return res.data;
    }catch (error) {
        flipLog.InfoDanger("assignedManager action",error);
        axiosErrorThrow(error);
    }
});



const assignedManagerAction = {
    list,
    add
};

export default assignedManagerAction;