import flipAlert from "../utils/alert/flipAlert";
import getFlipAxiosInstance, { axiosResponseError } from "../utils/flipAxios";
import flipLog from "../utils/logs/log.util";

const axiosInstance = getFlipAxiosInstance();
const urlprefix = "/manager/parcel-rates";

const show = async (id = undefined) => {
  try {
    const res = await axiosInstance.get(urlprefix + "/view");
    return res.data;
  } catch (error) {
    console.log("parcel Rate view error", error);
    flipAlert.showError(axiosResponseError(error));
  }
};
const update = async (data, id = undefined) => {
  try {
    const res = await axiosInstance.put(urlprefix + "/update", data);
    return res.data;
  } catch (error) {
    console.log("parcel Rate update error", error);
    flipAlert.showError(axiosResponseError(error));
  }
};

const add = () => {};

// -------------------

const managerParcelRateService = { show, update };

export default managerParcelRateService;
