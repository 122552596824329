import { createSlice } from "@reduxjs/toolkit";
import branchAction from "./action";
import flipAlert from "../../../utils/alert/flipAlert";

const initialState = {
  isLoading: false,
  list: [],
  view: {},
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  extraReducers: (builder) => {
    // -------add -----------
    builder.addCase(branchAction.addBranch.fulfilled, (state, action) => {
      const data = action?.payload;
      if (data?.branch) {
        state.list = [...state.list, data.branch];
      }
      flipAlert.showSuccess("branch added successfully");
    });
    builder.addCase(branchAction.addBranch.rejected, (state, action) => {
      console.log({ action });
      flipAlert.showError(action.error.message);
    });
    // ----------getAll -------------
    builder.addCase(branchAction.getAllBranch.fulfilled, (state, action) => {
      state.list = [...action.payload?.branches];
    });
    builder.addCase(branchAction.getAllBranch.rejected, (state, action) => {
      flipAlert.showError(action.error.message);
    });
    // -------- get--------
    builder.addCase(branchAction.getBranch.fulfilled, (state, action) => {
      state.view = { ...action.payload?.branch };
    });
    builder.addCase(branchAction.getBranch.rejected, (state, action) => {
      flipAlert.showError(action.error.message);
    });
    // -------delete ---------
    builder.addCase(branchAction.deleteBranch.fulfilled, (state, action) => {
      const deleteBranch = action.payload?.branch;
      const newList = state.list.filter((row) => row.id !== deleteBranch.id);
      state.list = [...newList];

      flipAlert.showSuccess("branch deleted successfully");
    });
    builder.addCase(branchAction.deleteBranch.rejected, (state, action) => {
      flipAlert.showError(action.error.message);
    });
  },
});

export const branchReducer = branchSlice.reducer;
