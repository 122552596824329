import { createSlice } from "@reduxjs/toolkit"
import assignedManagerAction from "./action"
import flipAlert from "../../../utils/alert/flipAlert";

const initialState ={
    isLoading: false,
    list :[],
    view :{},
};

const assignedManagerSlice = createSlice({
    name:'assignedManager',
    initialState,
    extraReducers:(builder)=>{
        // assigned-manager list
        builder.addCase(assignedManagerAction.list.fulfilled,(state,action)=>{
            console.log(action.payload);
            state.isLoading=false
            if (action?.payload?.assignedManagers){

                state.list=[...action?.payload?.assignedManagers] 
            }
        })
        builder.addCase(assignedManagerAction.list.pending,(state,action)=>{
            state.isLoading=true
        })
        builder.addCase(assignedManagerAction.list.rejected,(state,action)=>{
            state.isLoading=false
        })

        // assigned-mananger add
        builder.addCase(assignedManagerAction.add.fulfilled,(state,action)=>{
            state.isLoading=false
            state.list.push(action.payload.user)
        })
        builder.addCase(assignedManagerAction.add.pending,(state,action)=>{
            state.isLoading=true
            
        })
        builder.addCase(assignedManagerAction.add.rejected,(state,action)=>{
            state.isLoading=false
            flipAlert.showError(action.error.message);
            
        })
    }

})

export const assignedManagerReducer = assignedManagerSlice.reducer;