import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BaseLayout from '../panel/layouts/BaseLayout'
import { userPartyService } from '../../services'
import DataViewComp from '../../components/shared/data-info/DataViewComp'

const PartyViewPage = () => {

    const param = useParams()

    const [info, setInfo] = useState([])
    const [party, setParty] = useState({})

    React.useEffect(() => {
        userPartyService.view(param.id)
            .then(res => {
                console.log({ res })
                setParty(res.party)
            })
    }, [])

    React.useEffect(() => {
        setInfo([
            { label: "Name", value: party?.name },
            { label: "Email", value: party?.email },
            { label: "Mobile", value: party?.mobile },
            { label: "Address", value: party?.user_party_info?.address },
            { label: "GSTIN", value: party?.user_party_info?.gstin_no },
            { label: "Company Name", value: party?.user_party_info?.company_name },
            { label: "State", value: party?.user_party_info?.state_info?.name },
            { label: "District", value: party?.user_party_info?.district_info?.name },
            { label: "City", value: party?.user_party_info?.city_info?.office_name },
            { label: "PIN", value: party?.user_party_info?.pin },
        ])
    }, [party])


    return (
        <BaseLayout pageTitle={"Party Info"}>
            <div className="row">
                <div className="offset-lg-3 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <DataViewComp dataInfo={info} />
                        </div>
                    </div>
                </div>
            </div>
        </BaseLayout>

    )


}

export default PartyViewPage