class ParcelMapper {
  static createRequest(reqBody) {
    return {
      context: reqBody?.context,
      user_id: reqBody?.customer?.value,
      parcel_type_id: reqBody?.parcelType?.value,
      no_of_boxes: reqBody?.noOfBoxes,
      to_branch_id: reqBody?.toBranch?.value,
      weight: reqBody?.weight,
      valuation: reqBody?.valuation,
      weight_rate: reqBody?.weight_rate,
      valuation_rate: reqBody?.valuation_rate,
      discount_amount: reqBody?.discount_amount,
      gst_rate: reqBody?.gst_rate.value,
      payment_type: reqBody?.payment_type?.value,

      pickup_address: {
        address: reqBody?.pickupAddress?.address,
        state_id: reqBody?.pickupAddress?.state?.value,
        district_id: reqBody?.pickupAddress?.district?.value,
        city_id: reqBody?.pickupAddress?.city?.value,
        pin: reqBody?.pickupAddress?.pin,
      },
      dropoff_address: {
        address: reqBody?.dropoffAddress?.address,
        state_id: reqBody?.dropoffAddress?.state?.value,
        district_id: reqBody?.dropoffAddress?.district?.value,
        city_id: reqBody?.dropoffAddress?.city?.value,
        pin: reqBody?.dropoffAddress?.pin,
      },
    };
  }
}

export default ParcelMapper;
