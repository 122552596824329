import React from 'react'
import BaseLayout from '../panel/layouts/BaseLayout'
import FlipInput from '../../components/shared/forms/inputs/FlipInput'
import * as yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import branchManagerAction from '../../redux/slice/branch-manager/action';

const schema = yup
    .object({
        username: yup.string().required(),
        name: yup.string().required(),
        email: yup.string().required(),
        mobile: yup.string().required(),
        password: yup.string().required()

    })
    .required();


const BranchManagerAddPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // form submission section
    const {
        register,
        control,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        console.log('branchmanageraddpage onsubmit data ', data)

        dispatch(branchManagerAction.add(data))
            .unwrap()
            .then((result) => {
                console.log({ result });
                navigate("/branch-manager");
            })
            .catch((err) => { });
    }

    return (
        <BaseLayout pageTitle={'BranchManager Add'}>

            <div className="row">
                <div className="offset-lg-3 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)} className='row g-3'>

                                <div className="col-12">
                                    <FlipInput
                                        labelTitle='Username'
                                        register={register('username')}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12">
                                    <FlipInput
                                        labelTitle='Name'
                                        register={register('name')}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12">
                                    <FlipInput
                                        labelTitle='Email'
                                        register={register('email')}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12">
                                    <FlipInput
                                        labelTitle='Mobile'
                                        register={register('mobile')}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12">
                                    <FlipInput
                                        inputType={'password'}
                                        labelTitle='Password'
                                        register={register('password')}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="" className='form-label'></label>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </BaseLayout>
    )
}

export default BranchManagerAddPage