import React from "react";
import NavbarPage from "./NavbarPage";
import SidebarPage from "./SidebarPage";

const BaseLayout = ({children,pageTitle,pagination}) => {
  



  return (
    <section id="main-section" >
      <div>
        {/* ======= Header ======= */}
        <NavbarPage/>
        {/* End Header */}
        {/* ======= Sidebar ======= */}
        <SidebarPage/>
        {/* End Sidebar*/}

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>{pageTitle??""}</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">{pagination && pagination[0]}</a>
                </li>
                <li className="breadcrumb-item active">{pagination && pagination[1]}</li>
              </ol>
            </nav>
          </div>
          {/* End Page Title */}
          <section className="section dashboard">
            {children}
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer" className="footer">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>NiceAdmin</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ */}
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </footer>
        {/* End Footer */}
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </div>
    </section>
  );
};

export default BaseLayout;
