import flipAlert from "../utils/alert/flipAlert"
import getFlipAxiosInstance, { axiosResponseError } from "../utils/flipAxios"
import flipLog from "../utils/logs/log.util"


const axiosInstance = getFlipAxiosInstance()


const list = async () => {
    try {
        const res = await axiosInstance.get('/branch')

        return res.data
    } catch (error) {
        console.log('branch list error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}
const add = async (data) => {
    try {
        const res = await axiosInstance.post('/branch', data)

        return res.data
    } catch (error) {
        console.log('branch add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const edit = async ({id,data}) => {
    try {
        const res = await axiosInstance.put(`/branch/${id}`, data)

        return res.data
    } catch (error) {
        console.log('branch add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}

const view = async (id) => {
    try {
        const res = await axiosInstance.get(`/branch/${id}`)

        return res.data
    } catch (error) {
        console.log('branch add error', error)
        flipAlert.showError(axiosResponseError(error));
    }
}


const userBranchService = {
    add,
    list,
    edit,
    view
}
export default userBranchService