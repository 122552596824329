import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BaseLayout from "../panel/layouts/BaseLayout";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FlipSelect from "../../components/shared/forms/inputs/FlipSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import branchAction from "../../redux/slice/branch/action";
import branchManagerAction from "../../redux/slice/branch-manager/action";
import { useForm } from "react-hook-form";

const schema = yup
  .object({
    user_id: yup.object().required(),
    branch_id: yup.object().required(),
  })
  .required();

const AssignedManagerEditPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const branchState = useSelector((state) => state.branch);
  const branchManagerState = useSelector((state) => state.branchManager);

  const [branchOptions, setBranchOptions] = React.useState([]);
  const [managerNameOptions, setManagerNameOptions] = React.useState([]);

  const stateData = useSelector((state) => state.assignedManager);
  const [formValues, setFormValues] = React.useState([]);

  React.useEffect(() => {
    dispatch(branchAction.getAllBranch());
    dispatch(branchManagerAction.list());

    const branchOpts = branchState.list.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setBranchOptions(branchOpts);

    const managerNameOpts = branchManagerState.list.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setManagerNameOptions(managerNameOpts);
  }, []);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {};

  return (
    <BaseLayout pageTitle={"Assigned Manager Edit Page"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                <div className="col-12">
                  <FlipSelect
                    labelTitle={"Branch Manager Name"}
                    register={register("user_id")}
                    control={control}
                    options={managerNameOptions}
                    errors={errors}
                  />
                </div>

                <div className="col-12">
                  <FlipSelect
                    labelTitle={"Branch Name"}
                    register={register("branch_id")}
                    control={control}
                    options={branchOptions}
                    errors={errors}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AssignedManagerEditPage;
