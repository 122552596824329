import React from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { getNestedValue } from "../../../../utils/data/js-object.util";

const FlipSelect = ({
  labelTitle,
  labelRequired,
  options,
  value,
  defaultValue,
  errors,
  register,
  control,

  ...props
}) => {
  options = options ?? [];
  // const options = [
  //     // { value: 'chocolate', label: 'Chocolate' },
  //     // { value: 'strawberry', label: 'Strawberry' },
  //     // { value: 'vanilla', label: 'Vanilla' }
  //   ]

  const [dataOptions, setDataOptions] = React.useState(options);

  React.useEffect(() => {
    setDataOptions(options);
  }, [options]);

  return (
    <>
      {/* <label htmlFor="" className='form-label'>
                {labelRequired && (<span className='text-danger font-weight-bold'> *</span>)}
                {labelTitle}
            </label>
            <ReactSelect
                {...register}
                options={options}
                {...props} 
                />
            <p className="text-danger">{errors && errors[register?.name]?.message}</p> */}

      <label htmlFor="" className="form-label">
        {labelRequired && (
          <span className="text-danger font-weight-bold"> * </span>
        )}
        {labelTitle}
      </label>

      <Controller
        name={register?.name}
        control={control}
        defaultValue={value} // Optional default value
        render={({ field }) => (
          <ReactSelect
            isSearchable={props.isSearchable}
            placeholder={props.placeholder}
            {...field}
            options={dataOptions}
            onChange={(data) => {
              field.onChange(data);
              if (props.onChange) {
                props.onChange(data);
              }
            }}
            value={value}
            defaultValue={defaultValue}
          />
        )}
      />

      <p className="text-danger">
        {errors && getNestedValue(errors, register?.name)?.message}
      </p>
    </>
  );
};

export default FlipSelect;
