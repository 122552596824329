import React from "react";
import BaseLayout from "../panel/layouts/BaseLayout";

import { Link } from "react-router-dom";
import RouteUrl from "../../_config/constants/route_url";
import { useDispatch, useSelector } from "react-redux";
import branchAction from "../../redux/slice/branch/action";
import FlipDataTable from "../../components/flip-table/FlipDataTable";
import flipLog from "../../utils/logs/log.util";
import useFlipDataTableActionBtn from "../../hooks/useFlipDataTableActionBtn";
import { type } from "@testing-library/user-event/dist/type";

const BranchIndexPage = () => {
  const dispatch = useDispatch();
  const branchState = useSelector((state) => state.branch);

  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    dispatch(branchAction.getAllBranch());
  }, []);

  React.useEffect(() => {
    setTableData([...branchState.list]);
    // flipLog.Info("BranchIndexPage", tableData);
  }, [branchState.list.length]);

  const cols = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
  ];

  const deleteRow = (data) => {
    if (window.confirm("are u sure ?")) {
      console.log(data);
      dispatch(branchAction.deleteBranch(data.id));
    }
  };

  const columns = useFlipDataTableActionBtn(cols, [
    {
      type: "view",
      actionUrl: "/branch/:id",
      actionUrlParamObj: { id: "id" },
    },
    {
      type: "edit",
      actionUrl: "/branch/:id/edit",
      actionUrlParamObj: { id: "id" },
    },
    {
      type: "delete",
      // actionUrl: "/branch/:id",
      // actionUrlParamObj: { id: "id" },
      callback: deleteRow,
    },
  ]);

  // console.log({ columns });
  const data2 = [
    {
      id: 1,
      name: "hello",
    },
  ];

  return (
    <BaseLayout pageTitle={"Branch"}>
      <div className="row mb-3">
        <div className="col-12">
          <Link to={RouteUrl.branch.create} className="btn btn-primary">
            Add
          </Link>
        </div>
      </div>
      <FlipDataTable columns={columns} data={branchState.list} />
      {/* {branchState.list.length ? (
        <FlipDataTable columns={columns} data={tableData} />
      ) : (
        ""
      )} */}
      {/* {tableData.length ? <h1>Hello {tableData.length}</h1> : ""} */}
    </BaseLayout>
  );
};

export default BranchIndexPage;
