import React from "react";
import { FlipInput, FlipSelect } from "./inputs";
import locationAction from "../../../redux/slice/location/action";
import { useDispatch, useSelector } from "react-redux";

const AddressSetInputComp = ({
  register,
  errors,
  control,
  setValue,
  clearErrors,
  formValues,
  inputNames = {
    state: "state",
    district: "district",
    city: "city",
    pin: "pin",
  },
  divSize = {
    all: "col-12",
    country: "",
    state: "",
    district: "",
    city: "",
    pin: "",
  },
}) => {
  const [stateOptions, setStateOptions] = React.useState([]);
  const [distrctOptions, setDistrctOptions] = React.useState([]);
  const [citiesOptions, setCitiesOptions] = React.useState([]);

  const [userInput, setUserInput] = React.useState({
    state: "",
    district: "",
    city: "",
  });
  const dispatch = useDispatch();
  const locationState = useSelector((state) => state.location);

  // Initial Effect Call
  React.useEffect(() => {
    dispatch(locationAction.getStates());
  }, []);

  React.useEffect(() => {
    if (formValues) {
      setUserInput({
        ...userInput,
        state: formValues?.state ?? "",
        district: formValues?.district ?? "",
        city: formValues?.city ?? "",
        pin: formValues?.pin ?? "",
      });
    }
  }, [formValues]);

  React.useEffect(() => {
    const stateOpts = locationState.states.map((state) => {
      return {
        value: state.id,
        label: state?.name,
      };
    });
    // stateOpts.push(0,{value:'',label:'--SELECT--'})
    setStateOptions(stateOpts);

    if (userInput.state) {
      setDistrctOptions(
        locationState.districts?.map((item) => {
          return {
            value: item.id,
            label: item?.name,
          };
        })
      );
    }
    if (userInput.district) {
      setCitiesOptions(
        locationState.cities?.map((item) => {
          return {
            value: item.id,
            label: `${item.office_name} [PIN:${item.pincode}]`,
          };
        })
      );
    }
  }, [locationState]);

  // ===============handler================

  const handleState = async (data) => {
    console.log("INFO", "handleState", data);
    // const value = data.value
    setUserInput({ state: data, district: "", city: "" });
    setValue(inputNames.pin, "");
    if (data) {
      dispatch(locationAction.getDistricts(data.value));
    }
  };

  const handleDistrict = async (data) => {
    const id = data.value;
    setUserInput({ ...userInput, district: data, city: "" });
    dispatch(locationAction.getCities({ district_id: id }));
    setValue(inputNames.pin, "");
  };

  const handleCity = async (data) => {
    const id = data.value;
    setUserInput({ city: data });
    const city = locationState.cities.find((item) => item.id == data.value);
    console.log({ cityData: data });
    if (city) {
      console.log({ city });
      setValue(inputNames.pin, city.pincode);
      clearErrors(inputNames.pin);
    }

    // setUserInput({ ...userInput, district:data })
    // dispatch(locationAction.getCities({ district_id: id }))
  };

  // handle form submit
  const onSubmit = (data) => {
    console.log(data);

    const reqBody = {
      ...data,
      state: data?.state?.value,
      district: data?.district?.value,
      city: data?.city?.value,
    };
  };

  return (
    <>
      {/* country input */}
      <div className={divSize.country ?? divSize.all}>
        <label htmlFor="" className="form-label">
          Country
        </label>
        <select name="" id="" className="form-control">
          <option value="India">India</option>
        </select>
      </div>

      {/* states */}
      <div className={divSize.state ?? divSize.all}>
        <FlipSelect
          labelRequired
          labelTitle={"State"}
          register={register(inputNames?.state ?? "state")}
          control={control}
          options={stateOptions}
          errors={errors}
          onChange={(data) => handleState(data)}
          value={userInput.state}
        />
      </div>

      {/* districts */}
      <div className={divSize.district ?? divSize.all}>
        <FlipSelect
          labelRequired
          labelTitle={"District"}
          register={register(inputNames?.district ?? "district")}
          errors={errors}
          control={control}
          options={distrctOptions}
          onChange={(data) => {
            handleDistrict(data);
          }}
          value={userInput.district}
        />
      </div>

      {/* cities */}
      <div className={divSize.city ?? divSize.all}>
        <FlipSelect
          placeholder="type your city"
          isSearchable={true}
          labelTitle={"City/ PostOffice"}
          labelRequired
          register={register(inputNames?.city ?? "city")}
          errors={errors}
          control={control}
          options={citiesOptions}
          onChange={handleCity}
          value={userInput.city}
        />
      </div>
      {/* pincode */}
      <div className={divSize.pin ?? divSize.all}>
        <FlipInput
          inputVisibility={"readonly"}
          labelTitle={"PIN"}
          labelRequired
          register={register(inputNames?.pin ?? "pin")}
          errors={errors}
        />
      </div>
    </>
  );
};

export default AddressSetInputComp;
