import * as alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "./flip-alertify.css";

const showSuccess = (message) => {
  if (typeof message == "object") {
    message = JSON.stringify(message);
  }
  alertify.set("notifier", "position", "top-center");
  alertify.success(message);
};
const showError = (message) => {
  if (typeof message == "object") {
    message = JSON.stringify(message);
  }
  alertify.set("notifier", "position", "top-center");
  alertify.error(message);
};

const flipAlert = {
  showSuccess,
  showError,
};

export default flipAlert;
