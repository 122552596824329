import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const GuestRoute = ({children,...props}) => {
  const auth = useSelector((state) => state.auth);
  console.log('auth',auth.token);
  if(auth.token){
    return <Navigate to='/' />
  }else{

    return  children;
  }

};

export default GuestRoute;
