import axios from "axios";
import { BACKEND_API } from "../_config/constants/backend_api";
import authAction from "../redux/slice/auth/action";
import flipLog from "./logs/log.util";
import flipPreloader from "./preloader/flipPreloader";
// import { store } from "../redux/store"; // Import the store

const getFlipAxiosInstance = ({ isBaseURL = true } = {}) => {
  const flipAxios = axios.create({
    ...(isBaseURL && { baseURL: BACKEND_API.URL }),
    headers: {
      Accept: "application/json",
      // Authorization: `Bearer ${store.getState().auth.token}`,
    },
  });

  flipAxios.interceptors.request.use((config) => {
    const state = require("./reduxUtils").getReduxState();
    const token = state?.auth?.token;
    config.headers["Authorization"] = `Bearer ${token}`;
    flipPreloader.showPreloader();
    return config;
  });

  flipAxios.interceptors.response.use(
    (response) => {
      flipPreloader.hidePreloader();
      // console.log("INFO flipaxio response", response);
      return Promise.resolve(response);
    },
    (error) => {
      flipPreloader.hidePreloader();
      const state = require("./reduxUtils").getReduxState();
      if (error.response.status == 401) {
        const dispatch = require("./reduxUtils").getDispatch();
        // console.log("ERROR AUTH");

        state.auth?.token && dispatch(authAction.logout());
      }
      // console.log("axios error", error);
      // return error;
      // throw error
      return Promise.reject(error);
    }
  );

  return flipAxios;
};

// ----------------------------

export const axiosErrorThrow = (error) => {
  // console.log("error", error);
  const errMsg = error.response.data.message;
  // console.log({errMsg});
  flipLog.InfoDanger("errMsg", errMsg);
  throw new Error(errMsg);
};

export const axiosResponseError = (error) => {
  let errMsg = error?.response?.data?.message;
  if (error?.response?.status == 500) {
    errMsg = "Server Error";
  }

  return errMsg ?? "";
};

export default getFlipAxiosInstance;
