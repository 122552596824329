import React from 'react'
import BaseLayout from './panel/layouts/BaseLayout'

const DashboardPage = () => {
  return (
    <BaseLayout>
    
    </BaseLayout>
  )
}

export default DashboardPage