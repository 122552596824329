import React from "react";
import BaseLayout from "../../panel/layouts/BaseLayout";
import { FlipInput } from "../../../components/shared/forms/inputs";
import { useForm, yup, yupResolver } from "../../../utils/pkgs";
import { managerParcelRateService } from "../../../services";
import flipAlert from "../../../utils/alert/flipAlert";

const schema = yup
  .object({
    weight_rate: yup.number().positive().required(),
    valuation_rate: yup.number().positive().required(),
  })
  .required();

const ManagerParcelRateEditPage = () => {
  // const [parcelRate, setParcelRate] = React.useState({});
  const [formData, setFormData] = React.useState({});

  const getParcelRateData = () => {
    managerParcelRateService.show().then((res) => {
      if (res) {
        setFormData({
          weight_rate: res?.managerParcelRate?.weight_rate,
          valuation_rate: res?.managerParcelRate?.valuation_rate,
        });
      }
    });
  };

  React.useEffect(() => {
    getParcelRateData();
  }, []);

  // form submission section
  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: formData,
  });

  const onSubmit = (data) => {
    console.log("branchmanageraddpage onsubmit data ", data);

    managerParcelRateService.update(data).then((res) => {
      getParcelRateData();
      flipAlert.showSuccess("data updated successfully");
    });
  };

  return (
    <BaseLayout pageTitle={"Parcel Rates edit"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <form className="row g-2" onSubmit={handleSubmit(onSubmit)}>
                <FlipInput
                  inputType="number"
                  labelTitle={"Weight Rate (/KG)"}
                  labelRequired
                  register={register("weight_rate")}
                  errors={errors}
                  placeholder="Enter Weight rate"
                />
                <FlipInput
                  inputType="number"
                  labelTitle={"Valudation Rate (%)"}
                  labelRequired
                  register={register("valuation_rate")}
                  errors={errors}
                  placeholder="rate in percentage"
                />

                {/* submit */}
                <div className="col-12">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ManagerParcelRateEditPage;
