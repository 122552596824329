import React from "react";
import BaseLayout from "../panel/layouts/BaseLayout";
import { Link } from "react-router-dom";
import FlipDataTable from "../../components/flip-table/FlipDataTable";
import RouteUrl from "../../_config/constants/route_url";
import { userPartyService } from "../../services";
import useFlipDataTableActionBtn from "../../hooks/useFlipDataTableActionBtn";

const PartyIndexPage = () => {
  const [parties, setParties] = React.useState([]);

  const columns = useFlipDataTableActionBtn(
    [
      {
        name: "Name",
        selector: (row) => row.name,
      },
    ],
    [
      {
        type: "view",
        actionUrl: "/party/:id",
        actionUrlParamObj: { id: "id" },
      },
      {
        type: "edit",
        actionUrl: "/party/:id/edit",
        actionUrlParamObj: { id: "id" },
      },
      {
        type: "delete",
        actionUrl: "/party/:id",
        actionUrlParamObj: { id: "id" },
        callback: (data) => {
          if (window.confirm("are u sure ?")) {
            console.log(data);
          }
        },
      },
    ]
  );

  React.useEffect(() => {
    userPartyService.list().then((res) => {
      console.log({ res });
      if (res?.parties) {
        setParties(res?.parties);
      }
    });
  }, []);

  return (
    <BaseLayout pageTitle={"Party/Customer"}>
      <div className="row mb-3">
        <div className="col-12">
          <Link className="btn btn-primary" to={RouteUrl.party.create}>
            Add
          </Link>
        </div>
      </div>
      <FlipDataTable columns={columns} data={parties} />
    </BaseLayout>
  );
};

export default PartyIndexPage;
