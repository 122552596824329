import React from "react";
import { getNestedValue } from "../../../../utils/data/js-object.util";

const FlipInput = ({
  inputVisibility,
  inputType,
  labelTitle,
  labelRequired,
  errors,
  register,
  placeholder = "",
  defaultValue = "",
  onChange = () => {},
  onKeyUp = () => {},
  ...props
}) => {
  if (!register) {
    register = {};
  } else {
    // console.log(register);
  }

  inputVisibility = inputVisibility ?? {};

  return (
    <>
      <label htmlFor="" className="form-label">
        {/* for required label */}
        {labelRequired && labelRequired != "optional" && (
          <span className="text-danger font-weight-bold"> *</span>
        )}

        {labelTitle}
        {/* for optional label */}
        {labelRequired && labelRequired == "optional" && (
          <span className="text-secondary font-weight-bold"> (Optional)</span>
        )}
      </label>

      <input
        {...register}
        readOnly={inputVisibility == "readonly"}
        disabled={inputVisibility == "disabled"}
        type={inputType ? inputType : "text"}
        className="form-control"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onchange}
        onKeyUp={onKeyUp}
      />
      <p className="text-danger">
        {errors && getNestedValue(errors, register?.name)?.message}
      </p>
    </>
  );
};

export default FlipInput;
