import React, { useState } from "react";
import BaseLayout from "../../panel/layouts/BaseLayout";
import {
  FlipInput,
  FlipInputTextArea,
} from "../../../components/shared/forms/inputs";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import parcelTypeService from "../../../services/master/parcel-type.service";
import flipAlert from "../../../utils/alert/flipAlert";
import FlipInputFile from "../../../components/shared/forms/inputs/files/FlipInputFile";
import ImageViewComp from "../../../components/shared/data-info/ImageViewComp";

const schema = yup
  .object({
    name: yup.string().required(),
    logo: yup.string().nullable(),
    description: yup.string().required(),
  })
  .required();

const ParcelTypeEditPage = () => {
  const navigate = useNavigate();

  const [parcelType, setParcelType] = useState({});
  const [formValues, setFormValues] = useState({});

  const params = useParams();

  React.useEffect(() => {
    parcelTypeService.show(params.id).then((res) => {
      setParcelType(res.parcelType);
    });
  }, []);

  React.useEffect(() => {
    setFormValues({
      name: parcelType?.name,
      description: parcelType?.description,
    });
  }, [parcelType]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: formValues,
  });

  const onSubmit = async (data) => {
    const resData = await parcelTypeService.update({ id: params.id, data });
    if (resData) {
      flipAlert.showSuccess("data updated successful");
      navigate("/parcel-types");
    }
  };

  return (
    <BaseLayout pageTitle={"Parcel Edit"}>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="row g-2">
                <FlipInput
                  inputType="text"
                  labelTitle={"Name"}
                  labelRequired
                  register={register("name")}
                  errors={errors}
                />

                {/* <img style={{ width: "100px" }} src={parcelType?.logo_url} /> */}
                <ImageViewComp url={parcelType?.logo_url} />
                <FlipInputFile
                  labelTitle={"Logo"}
                  labelRequired
                  register={register("logo")}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />

                <FlipInputTextArea
                  inputType="text"
                  labelTitle={"Description"}
                  labelRequired
                  register={register("description")}
                  errors={errors}
                  row={3}
                />
                <div className="col-12">
                  <label htmlFor="" className="form-label"></label>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ParcelTypeEditPage;
